import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";
const funciones = {
    PassAleatorio(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    formatArray(myArray){
        let string = []
        let final = []
        myArray.forEach(e => {
            string.push(JSON.stringify(e))
        });
        var myUniqueArray1 = [...new Set(string)];
        myUniqueArray1.forEach(e => {
            final.push(JSON.parse(e))
        });
        return final
    },
    async user() {
        const user = await Wrapper.getData(Url+'users')
        // console.log('user');
        // console.log(user);
        if (user !== undefined) {
            return await this.menu(user.data.data.user_id)
        }
        // return await user.data.data
    },
      async menu(id) {
        const menu = await Wrapper.getData(Url+'users/menu/'+id)
        // console.log('menu');
        return await menu.data.data
    },
    imprimir(nombreDiv) {
        var contenido= document.getElementById(nombreDiv).innerHTML;
        var contenidoOriginal= document.body.innerHTML;
        document.body.innerHTML = contenido;
        window.print();
        document.body.innerHTML = contenidoOriginal;
    },
    fechaFormat(data){
        let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let fecha = data.split(' ')
        let format = fecha[0].split('-')
        // console.log(mes[format[1]-1]);
        let formatF = format[2]+'/'+mes[format[1]-1]+'/'+format[0]
        return formatF
    },
    formatPrice(data){
        let cant = new Intl.NumberFormat("en-IN").format(data)
        let miles = cant.split(',')
        if (miles.length>1) {
            cant = miles.join("") 
        }
        return  '$'+this.format(parseFloat(cant).toFixed(2))
    },
    format(n, sep, decimals) { 
        n = n.toString() 
        while (true) { 
        var n2 = n.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3') 
        if (n === n2) break 
        n = n2 
        } 
        return n 
    } ,
    formatPriceC(data,moneda){
        let cant = new Intl.NumberFormat("en-IN").format(data)
        let miles = cant.split(',')
        if (miles.length>1) {
            cant = miles.join("") 
        }
        if (moneda === 'MXN') {
            return  '$'+this.format(parseFloat(cant).toFixed(2))
        }else{
            return  '€'+this.format(parseFloat(cant).toFixed(2))
        }
    },
    getKeys(data){
        let keys = Object.keys(data)
        return keys
    }
}
export default funciones