import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {messageService} from "../hooks/global";
import Axios from "axios";
import Url from "../hooks/url";
import Functions from "../hooks/functions";
import Wrapper from "../hooks/wrapper";

class pop extends React.Component{
    constructor(){
        super();
        this.state={
            cartItems:{}
        }
    }
    cierra(data){
        this.props.activePop(data)
    }
    createMarkup() {
        const { CourseRequirements } = this.props.data
        return {
           __html: CourseRequirements    };
     };
    async getcart(){
        // console.log(Url+'cart');
        const cart = await Wrapper.getData(Url+'cart')
        if (cart.data.data !== null) {
            this.setState({cartItems:cart.data.data})
        }
        this.addCar()
    }
    buyCart(url){
        let data = {}
        data.item_id = this.props.data.CourseID
        data.item_type = ''
        // console.log(data);
        Axios.post(Url+url,data,{withCredentials:true})
        .then(res=>{
            messageService.sendMessage(res.data.data.cart.items);
        })
        .catch(err=>{
            if (err.response.data.error.repeated) {
                // console.log(cartItems.total_items);
                this.setState({alerta:true})
            }
        })
    }
    alerta(){
        const {alerta} = this.state
        if (alerta) {
            setTimeout(() => {
                this.setState({alerta:false})
            }, 3100);        
            return(
                <div className="alerta">El producto ya esta agregado</div>
            )
        }
    }
    addCar(){
        const {cartItems} = this.state
        if (Object.keys(cartItems).length === 0) {
            this.buyCart('cart/add')
        }else{
            this.buyCart('cart/'+cartItems.id)
        }
    }
    render(){
        const { CourseImage,CourseName,CourseDuration,CourseDescription,CoursePrice,CourseCategory,CourseCurrency,CourseLevel,CourseSessions } = this.props.data
        return(
            <div className="pop centrar">
                {this.alerta()}
                <div className="close closePOp" onClick={()=>this.cierra(false)}>
                    <FontAwesomeIcon icon={faTimesCircle} className="fz-40"/>
                </div>
                <div className="card horizontal w-70 m-auto">
                    <div className="card-image negro">
                        <img className="h-100 m-auto" src={CourseImage} alt="curso"/>
                    </div>
                    <div className="card-stacked p-20">
                        <div className="card-content">
                            <strong>{CourseName}</strong>
                            <div className="mt-20 left-align">
                                <strong>Descripcion:</strong>
                                <p>{CourseDescription}</p>
                            </div>
                            <div className="centrar mt-20">
                                <strong>Requisitos del curso: </strong>
                                <div className="ml-5" dangerouslySetInnerHTML={this.createMarkup()} /> 
                            </div>
                            <div className="centrar mt-10">
                                <strong>Nivel del curso: </strong>
                                <span>{CourseLevel}</span> 
                            </div>
                            <div className="centrar mt-10">
                                <strong>Numero de sesiones: </strong>
                                <span className="ml-4">{CourseSessions}</span> 
                            </div>
                            <div className="row mt-20 mb-0">
                                <small className="col s8 l8 left-align">
                                   <strong>Categoria: </strong> {CourseCategory}
                                </small>
                                <small className="col s4 l4 right-align">
                                    {CourseDuration}
                                </small>
                            </div>
                            
                        </div>
                        <div className="card-action">
                            <div className="row">
                                <div className={this.props.position === 'horizontal'?'col s12 l6':'col s6 l6'} >
                                    <button className="waves-effect btn bg-orange" onClick={()=>this.getcart()}>
                                        Comprar
                                    </button>
                                </div>
                                <div className={this.props.position === 'horizontal'?'col s12 l6 right-align price':'col s6 l6 right-align'}>
                                    {Functions.formatPrice(CoursePrice)} {CourseCurrency}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default pop