import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram, faLinkedinIn, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import Logo from "../img/X-xpertcad3.png";

class foter extends React.Component{
    render(){
        return(
            <footer className="page-footer grey darken-4 center-align white-text sp">
                <div className="footer-copyright">
                    <div className="w-83 m-auto">
                        <div className="row mn centrar">
                            <div className="col l4 s12 centrar">
                                <div className="row text-center m-auto">
                                    <div className="col s3 l3">
                                        <a href="https://www.facebook.com/pg/xpertcad/about/?ref=page_internal" target='blank' className="white-text">
                                            <FontAwesomeIcon icon={faFacebookSquare} className="fa-2x "/>
                                        </a>
                                    </div>
                                    <div className="col s3 l3">
                                        <a href="https://twitter.com/xpertcad" target="blank" className="white-text">
                                            <FontAwesomeIcon icon={faTwitter} className="fa-2x ml-10"/>
                                        </a>
                                    </div>
                                    <div className="col s3 l3">
                                        <a href="https://www.instagram.com/accounts/login/?next=/xpertcad/" target="blank" className="white-text">
                                            <FontAwesomeIcon icon={faInstagram} className="fa-2x ml-10"/>
                                        </a>
                                    </div>
                                    <div className="col s3 l3">
                                        <a href="https://www.linkedin.com/showcase/xpertcad/" target="blank" className="white-text">
                                            <FontAwesomeIcon icon={faLinkedinIn} className="fa-2x ml-10"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col l4 s12 text-center centrar">
                                <h6 className="m-auto white-text">XpertCAD® 2020. All Rights Reserved.</h6>
                            </div>
                            <div className="col l4 s12 centrar">
                                <img src={Logo} alt="logo" className="w-50 m-auto"/>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            // <div className="grey darken-3 center-align white-text p-10">
            //     Fotter de prruaba - Terminos y condiciones - Site map
            // </div>
        )
    }
}
export default foter