import React from "react";
// import Oxxo from "../../img/pagooxxo.png";
import Oxxopay from "../../img/oxxopay_brand.png";
import Url from "../../hooks/url";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import funciones from "../../hooks/functions";

class oxxo extends React.Component{
    constructor(){
        super();
        this.state={
            oxxo:{}
        }
        this.buyOxxo = this.buyOxxo.bind(this);
    }
    componentDidMount(){
        const {data}=this.props
        this.setState({oxxo:data})
    }
    buyOxxo(){
        const {id}=this.props
        let data = {
            PaymentMethodID: "2",
            ReferenceCode: ""
        }
        console.log(data);
        Axios.post(Url+'orders/'+id+'/buying',data,{withCredentials:true})
        .then(res=>{
            // console.log(res);
            this.setState({oxxo:res.data.data.stub})
        })
        .catch(err=>{
            console.log(err.response);
        })
    }
    render(){
        const {oxxo} = this.state
        if (Object.keys(oxxo).length === 0) {
            return(
                <div className="centrar">
                    {this.buyOxxo()}
                </div>
            )
        }
        else{
            return(
                <div className="tarjeta text-center">
                    <button className="btn grey darken-4 imprimir" onClick={()=>funciones.imprimir('oxxo')}>
                        <FontAwesomeIcon icon={faPrint}/>
                        <span className="ml-5">Imprimir</span>
                    </button>
                    <div>Imprime o descarga el talon de pago o consultalo en tus pedidos</div>
                    <div id="oxxoP">
                        <div className="opps" id="oxxo">
                            <div className="opps-header">
                                <div className="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                                <div className="opps-info row centrar dblock">
                                    <div className="col s12 l5">
                                        <div className="opps-brand"><img src={Oxxopay} alt="OXXOPay"/></div>
                                    </div>
                                    <div className="col s12 l7">
                                        <div className="opps-ammount">
                                            <h4>Monto a pagar</h4>
                                            <h5>$ {oxxo.Amount} <sup>{oxxo.Currency}</sup></h5>
                                            <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="opps-reference">
                                    <h3>Referencia</h3>
                                    <img src={oxxo.BarUrl} alt="barras" className="barras"/>
                                    <h4 className="text-center">{oxxo.Reference}</h4>
                                </div>
                            </div>
                            <div className="opps-instructions">
                                <h4>Instrucciones</h4>
                                <ol>
                                    <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/">Encuéntrala aquí</a>.</li>
                                    <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                                    <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                    <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                    <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                                </ol>
                                <div className="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>
                            </div>
                        </div>
                    </div>	
                </div>
            )
        }
    }
}
export default oxxo