import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import Axios from "axios";
import '../styles/styles.scss'
import { Link } from "react-router-dom";

import Loader from "../components/loader";
// import Navbar from "../components/navbar";
// import Foter from "../components/foter";
import Product from "../components/product";
import ButtonBuy from "../components/buttonBuy";
import CarContext from "../hooks/car";
import Functions from "../hooks/functions";
import Wrapper from "../hooks/wrapper";
import {messageService} from "../hooks/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";

class instructor extends React.Component{
    constructor() {
        super();
        this.state= {
            loader:true,
            nav:{},
            listcar:{},
            keys:[],
            id:'',
            exist:0
        }
        this.getCart = this.getCart.bind(this);
        this.VaciarCart = this.VaciarCart.bind(this);
        this.getKeys = this.getKeys.bind(this);
    }
    componentDidMount(){
        // messageService.sendMessage('Tienda,/ > Carrito de compras,/Mycar','bread');
        messageService.sendMessage('Tienda,/ > Carrito de compras','bread')
        this.setState({nav:Apis.navbar})
        this.setState({listcar:Apis.car})
        this.existcart()
    }

    componentDidUpdate(){
        this.decri()
    }
    
    async getCart(id){
        this.setState({listcar:{}})
        const getCart = await Wrapper.getData(Url+'cart/'+id)
        console.log(getCart);
        if (getCart.data.data.cart.items !== undefined) {
            this.setState({listcar:getCart.data.data.cart})
            this.getKeys()
        }else{
            this.setState({listcar:{asd:'1'}})
            this.getKeys(true)
        }
    }
    async existcart(){
        if (this.state.exist === 0) {
            const cart = await Wrapper.getData(Url+'cart')
            // console.log(cart);
            this.setState({exist:1})
            if (cart.data.data !== null) {
                this.setState({id:cart.data.data.id})
                this.getCart(cart.data.data.id)
            }
        }
        let invitado = localStorage.getItem('invitado')
        let car = localStorage.getItem('myCart')
        if (invitado !== null) {
            this.getCart(car)
        }
    }
    getKeys(data){
        let llaves = []
        if (this.state !== undefined) {
            if (this.state.listcar.items !== undefined) {
            llaves = Object.keys(this.state.listcar.items)
            messageService.sendMessage(this.state.listcar.items);
            }
        }
        if (data) {
            llaves = []
            messageService.sendMessage({});
        }
        this.setState({keys:llaves})
    }
    decri(){
        let des = document.getElementById("decri")
        if (des !== null) {
            des.innerHTML = this.state.instructor.description
        }
    }
    VaciarCart(){
        // console.log(this.state.id);
        Axios.delete(Url+'cart/'+this.state.id+'/all')
        .then(res=>{
            this.getKeys(true)
            // this.getCart(this.state.id)
        })
        .catch(err=>{
            console.log(err);
        })
    }
    render(){
        if (Object.keys(this.state.listcar).length === 0) {
            return <Loader />
        }
        else if (this.state.keys.length === 0) {
            return(
                <div>
                    {/* <Navbar menu={this.state.nav}/> */}
                        <div className="w-80 m-auto p-20 hminvh-78">
                            <h5>Carrito de compras</h5>
                            <div className="card p-20 w-100 m-auto text-center">
                                <h2 className="center-align">No hay productos en el carrito</h2>
                                <Link to="/" className="btn bg-orange">Ir a cursos</Link>
                            </div>
                        </div>
                    {/* <Foter/> */}
                </div>
            )
        }
        else {
            // console.log(this.state.listcar);
            const {keys} = this.state
            const {total_amount,currency_code,total_discount,total_tax,total_total,items} = this.state.listcar 
            return(
                <div>
                    {/* <Navbar menu={this.state.nav}/> */}
                        <div className="w-80 m-auto p-20 hminvh-76">
                            <h5>Carrito de compras</h5>
                            <div className="row">
                                <div className="col s12 l8 sp pr-20">
                                    <div className="card p-20 w-100">
                                        {keys.map((e,index)=>{
                                            var itemsData = {}
                                            if (items !== undefined) {
                                                if (items[e] !== undefined) {
                                                    itemsData = items[e]
                                                }
                                            }
                                            return(
                                                <div key={"listc"+index}>
                                                    <Product data={itemsData} getCart={this.getCart} getKeys={this.getKeys}/>
                                                    <hr/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="row">
                                        <div className="col s5 l5">
                                            <Link className="btn transparent dark-text" to='/'>
                                                <FontAwesomeIcon icon={faArrowLeft}/>
                                                <span className="ml-10">SEGUIR COMPRANDO</span>
                                            </Link>
                                        </div>
                                        <div className="col s2 l2">

                                        </div>
                                        <div className="col s5 l5 righ-text">
                                            <Link to="/#titleC" className="btn transparent dark-text" onClick={this.VaciarCart}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                                <span className="ml-10">VACIAR CARRITO</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 l4 sp stiky-f">
                                    <div className="grey lighten-3 p-20 card">
                                        {/* <div className="cupon">
                                            <span className="grey lighten-3">Cupón de descuento</span>
                                            <input type="text" placeholder="Código" className="mt-5"></input>
                                        </div> */}
                                        {/* <hr/> */}
                                        <div className="row">
                                            <div className="col s6 l6">
                                                <p><strong>Subtotal: </strong></p>
                                                {parseInt(total_discount)>0?
                                                <p><strong>Descuento:</strong></p>
                                                :<div></div>}
                                                <p><strong>Impuestos:</strong></p>
                                            </div>
                                            <div className="col s6 l6 right-align">
                                                <p>{Functions.formatPrice(total_amount)} {currency_code}</p>
                                                {parseInt(total_discount)>0?
                                                <p>{Functions.formatPrice(total_discount)} {currency_code}</p>
                                                :<div></div>}
                                                <p>{Functions.formatPrice(total_tax)} {currency_code}</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col s6 l6">
                                                <p><strong>Total</strong></p>
                                            </div>
                                            <div className="col s6 l6 right-align">
                                                <p><strong>{Functions.formatPrice(total_total)} {currency_code}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonBuy data={this.state.id}/>
                                </div>
                            </div>
                        </div>
                    {/* <Foter/> */}
                </div>
            )
        }
    }
}
instructor.contextType = CarContext;
export default instructor