import React from "react";

class formCD extends React.Component{
    constructor() {
        super();
        this.state= {
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
  
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    showAlert = () => {
        const {id} = this.props
        window.TarjetaCD(this.state,id)
    }
 
    render(){
        return(
            <form>
                <span className="card-errors"></span>
                <div>
                    <label>
                        <span>Titular de la tarjeta</span>
                        <input type="text" size="20" name="name" onChange={this.handleInputChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        <span>Card number</span>
                        <input type="number" size="20" name="number" onChange={this.handleInputChange}/>
                    </label>
                </div>
                <div className="row">
                    <div className="col s6">
                        <div>
                            <label>
                                <span>Expiration date (MM/YYYY)</span>
                                <div className="row">
                                    <div className="col s6">
                                        <input type="number" size="2" name="exp_month" onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col s6">
                                        <input type="number" size="4" name="exp_year" onChange={this.handleInputChange}/>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col s3"></div>
                    <div className="col s3">
                        <div>
                            <label>
                                <span>CVC</span>
                                <input type="text" size="4" name="cvc" onChange={this.handleInputChange} maxLength="3"/>
                            </label>
                        </div>
                    </div>
                </div>
                {/* <button className="btn bg-orange" onClick={this.create}>Realizar pago</button> */}
            </form>
        )
    }
}
export default formCD