import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Axios from "axios";
import Url from "../../hooks/url";

class popFactura extends React.Component {
    constructor() {
        super();
        this.state= {
            fiscal:'',
            totalFactura:'',
            fecha:'',
            files:{},
            banks:[],
            fileName:'',
            month:['01','02','03','04','05','06','07','08','09','10','11','12'],
            type:''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileData = this.fileData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
    }
    componentDidMount(){
        const {month}= this.state
        let hoy = new Date()
        hoy = hoy.getFullYear()+'-'+month[hoy.getMonth()]+'-'+hoy.getDate()
        this.setState({fecha:hoy})
        this.setState({type:this.props.type})
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    fileData(event){
        // console.log(this.fileInput.current.files[0]);
        this.setState({files:this.fileInput.current.files[0]})
        this.setState({fileName:this.fileInput.current.files[0].name})
    }
    handleSubmit(event) {
        event.preventDefault();
        // const { fiscal,files,fecha,totalFactura } = this.state
        const { files,type } = this.state
        const { id } = this.props
        var urlP = 'hola';
        let formData = new FormData();
        console.log(type);
        switch (type) {
            case 'payment':
                urlP = Url+'orders_sellers/'+id+'/payment'
                formData.append('SPaymentInvoiceXML', files)
                break;
            case 'invoice':
                urlP = Url+'orders_sellers/'+id+'/author/invoices'
                formData.append('OSInvoiceFileNameXML', files)
                break;
            default:
                break;
        }
        console.log(urlP);
        console.log(formData);
        // formData.append('SPaymentInvoiceDate', fecha)
        // formData.append('SPaymentInvoiceUID', fiscal)
        // formData.append('SPaymentInvoiceTotal', totalFactura)
        
        Axios.post(urlP,
        formData,
            {
                headers: {
                    'Content-Type': 'multipart / form-data; límite = <calculado cuando se envía la solicitud>'
                }
            },{withCredentials:true}
        )
        .then(res=>{
            this.props.getInicial()
            this.props.activePop(false)
            console.log(res);
        })
        .catch(err=>{
            this.props.getInicial()
            this.props.activePop(false)
            console.log(err.response);
        })
      }
    render(){
        return(
            <div className="pop centrar">
                <div className="close closePOp" onClick={()=>this.props.activePop(false)}>
                    <FontAwesomeIcon icon={faTimesCircle} className="fz-40"/>
                </div>
                <div className="card w-60 m-auto p-20">
                    <h5>Datos de la factura</h5>
                    <form>
                        {/* <div className="input-field">
                            <input type="text" placeholder="Folio Fiscal" id="fiscal" name="fiscal" onChange={this.handleInputChange}/>
                        </div>
                        <div className="input-field">
                            <input type="number" placeholder="Total Factura" id="totalFactura" name="totalFactura" onChange={this.handleInputChange}/>
                        </div>
                        <div className="row">
                            <div className="col s6 l6 input-field">
                                <input type="date" placeholder="Fecha de factura" id="FechaFactura" name="fecha" onChange={this.handleInputChange} value={this.state.fecha}/>
                                <label htmlFor="FechaFactura">Fecha de factura</label>
                            </div>
                            <div className="col s6 l6">
                                <label className="file2">
                                    <span>Subir archivo para verificar pago</span>
                                    <input type="file" name="file" onChange={this.fileData} ref={this.fileInput} className="oculto"/>
                                    <div className="dataFile">{this.state.fileName}</div>
                                </label>
                            </div>
                        </div> */}
                        <div className="input-field">
                            <label className="file2">
                                <span>Subir archivo para verificar pago</span>
                                <input type="file" name="file" onChange={this.fileData} ref={this.fileInput} className="oculto"/>
                                <div className="dataFile">{this.state.fileName}</div>
                            </label>
                        </div>
                        <button className="btn bg-orange mt-20" onClick={()=>this.props.activePop(false)}>Cancelar</button>
                        <button className="btn grey darken-4 ml-20 mt-20" onClick={this.handleSubmit}>
                            Subir factura
                        </button>
                    </form>
                </div>
            </div>
        )

    }
}

export default popFactura