import React from "react";
import { Link } from "react-router-dom";
import Pop from "./pop";
import {messageService} from "../hooks/global";
import Axios from "axios";
import Url from "../hooks/url";
import Functions from "../hooks/functions";
import Wrapper from "../hooks/wrapper";
// import consumerAPIS from "../hooks/consumerAPIS";

class card extends React.Component{
    constructor() {
        super();
        this.state= {
            cartItems:{},
            pop:false,
            idPop: Number,
            carProduct:[],
            alerta:false
        }
        this.activePop = this.activePop.bind(this);
    }
    componentDidMount() {
        // subscribe to home component messages
        this.subscription = messageService.getMessage().subscribe(message => {
            if (message) {
                // add message to local state if not empty
                this.setState({ carProduct: message.text });
            } else {
                // clear messages when empty message received
                this.setState({ messages: [] });
            }
        });
    }

    componentWillUnmount() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
    asigId(){
        this.setState({idPop:this.props.indice})
        this.activePop(true)
    }
    activePop(data){
        this.setState(() => {
            return { pop: data};
          })
    }
    popup(){
        if (this.state.pop) {
            if (this.state.idPop === this.props.indice) {
                return(<Pop data={this.props.data} activePop={this.activePop}></Pop>)
            }   
        }
    }
    async getcart(){
        const cart = await Wrapper.getData(Url+'cart')
        // console.log('cart homes');
        // console.log(cart);
        if (cart !== undefined) {
            this.setState({cartItems:cart.data.data})
        }
        this.addCar()
    }
    async buyCart(url){
        let data = {}
        data.item_id = this.props.data.CourseID
        data.item_type = ''
        Axios.post(Url+url,data,{withCredentials:true})
        .then(res=>{
            localStorage.setItem('invitado',res.data.data.cart.guest_id)
            localStorage.setItem('myCart',res.data.data.cart.id)
            localStorage.setItem('compra',this.props.data.CourseID)
            messageService.sendMessage(res.data.data.cart.items,'Cproducto');
            let key = Object.keys(res.data.data.cart.items)
            key.forEach(e => {
                if (res.data.data.cart.items[e].item_id === this.props.data.CourseID.toString()) {
                    messageService.sendMessage('buy','typeAlerta');
                    messageService.sendMessage(res.data.data.cart.items[e],'alerta');
                }
            });
            // localStorage.setItem()
        })
        .catch(err=>{
            console.log(err.response);
            if (err.response !== undefined) {
                if (err.response.data.error.repeated) {
                    console.log(err.response);
                    // this.setState({alerta:true})
                    messageService.sendMessage('repeat','typeAlerta');
                    messageService.sendMessage('false','alerta');
                }
            }
        })
    }
    addCar(){
        // const {cartItems} = this.state
        let carro = localStorage.getItem('myCart')
        let invitado = localStorage.getItem('invitado')
        if (carro === null) {
            if(invitado !== null){
                this.buyCart('cart/'+ invitado)
            }else{
                this.buyCart('cart/add')
            }
        }else{
            // console.log('carro');
            this.buyCart('cart/'+carro)
        }
    }
    alerta(){
        const {alerta} = this.state
        if (alerta) {
            setTimeout(() => {
                this.setState({alerta:false})
            }, 3100);        
            return(
                <div className="alerta">El producto ya esta agregado</div>
            )
        }
    }
    render(){
        // console.log(this.props.data);
        const { CourseImage,CourseName,CourseDuration,CourseDescriptionShort,CoursePrice,CourseID,CourseCategory,CourseCurrency,CourseThumbnail,AuthorName } = this.props.data
        return(
            <div>
                {this.alerta()}
                <div className={"card "+this.props.position}>
                    <div className="card-image pointer" onClick={()=>this.asigId()}>
                        {/* <div className="bg-orange bages-cursos">Nuevo</div> */}
                        <div className="logo-autor">img</div>
                        {CourseThumbnail !== undefined?
                        <img className="h-100" src={CourseThumbnail} alt="curso"/>:
                        <img className="h-100" src={CourseImage} alt="curso"/>
                        }
                    </div>
                    <div className="card-stacked">
                        <div className="card-content">
                            <div className="row sm">
                                <small className="col s8 l8 left-align hpx-61 sp">
                                    <strong>{CourseName}</strong>
                                </small>
                                <small className="col s4 l4 text-orange">
                                    <strong>{Functions.formatPrice(CoursePrice)} {CourseCurrency}</strong>
                                </small>
                            </div>
                            {CourseDescriptionShort === ''?
                            <p className="left-align mt-20">{CourseDescriptionShort}</p>
                            :<div></div>}
                            <p className="left-align"><small className="mt-20">Creado por <strong>{AuthorName}</strong></small></p>
                            <div className="row mt-20 mb-0">
                                {CourseCategory !== null?
                                <small className="col s6 l6 left-align centrar">
                                    <i className="material-icons-outlined text-orange">category</i> {CourseCategory}
                                </small>
                                :<div className="col s6 l6"></div>}
                                {CourseDuration !== null?
                                <small className="col s4 l4 left-align centrar">
                                    <i className="material-icons-outlined text-orange">access_time</i> {CourseDuration}
                                </small>
                                :<div className="col s4 l4"></div>}
                                <small className="col s2 l2 right-align centrar">
                                    <Link to={"/Courses/"+CourseID} className="waves-effect waves-light-blue ml-auto">
                                        <i className="material-icons-outlined text-orange ml-auto">arrow_forward</i> 
                                    </Link>
                                </small>
                            </div>
                        </div>
                        <div className="card-action">
                            <button className="waves-effect btn bg-orange w-100 centrar carrito" onClick={()=>this.getcart()}>
                                <i className="material-icons-outlined mr-20">shopping_cart</i> <span>Agregar al carrito</span>
                            </button>
                        </div>
                    </div>
                </div>
                {this.popup()}
            </div>
        )
    }
}
export default card
