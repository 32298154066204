import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import Wrapper from "../hooks/wrapper";
import {messageService} from "../hooks/global";
import '../styles/styles.scss'
import { Link } from "react-router-dom";

import Loader from "../components/loader";
import FormCD from "../components/methodsBuy/formCD";
import Transfer from "../components/methodsBuy/transfer";
import Oxxo from "../components/methodsBuy/oxxo";
import Axios from "axios";
import DetallesOrden from "../components/methodsBuy/detallesOrden";
import imgPaypal from "../img/paypal-logo-png-transparent.png";
import imgOxxo from "../img/oxxo.png";
import imgVisa from "../img/visa.png";
import imgTrans from "../img/spei.png";

class orden extends React.Component{
    constructor() {
        super();
        this.state= {
            view:"buy",
            principal:"inicial",
            loader:true,
            orden:{},
            nav:{},
            ordens:{},
            products:[],
            formsurvey:0,
            button:0,
            id:'',
            metodos:{},
            keyMetodos:[],
            terminos:false
        }
        this.cancelOrder = this.cancelOrder.bind(this);
        this.finalizar = this.finalizar.bind(this);
        this.realizarPago = this.realizarPago.bind(this);
        this.formaPago = this.formaPago.bind(this);
        this.buttonCgange = this.buttonCgange.bind(this);
        this.createButton = this.createButton.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount(){
        messageService.sendMessage('Tienda,/ > Pago de carrito de compras','bread')
        this.setState({nav:Apis.navbar})
        this.getStore()
    }
    getStore(){
        // console.log(localStorage.getItem('orden'));
        if (localStorage.getItem('orden') !== null) {
            let data = JSON.parse(localStorage.getItem('orden'))
            let metods = JSON.parse(localStorage.getItem('metodos'))
            // console.log(data);
            let keys = Object.keys(metods)
            this.setState({metodos:metods})
            this.setState({keyMetodos:keys})
            this.getData(Url+'orders/'+data.OrderID,'ordens')
        }
    }
    createButton(cant){
        const {button,ordens} = this.state
        let pay = document.querySelector('#paypal-button-container')
        if (pay !== null && button === 0) {
            this.setState({button:1})
            window.globalMia(cant,ordens.OrderID)
        }
        // this.decri()
    }
    getPaypal(idClient,curren,cant){
        const {button} = this.state
        document.getElementsByTagName('script')[1].attributes[0].value = 'https://www.paypal.com/sdk/js?client-id='+idClient+'&currency='+curren+'&disable-funding=credit,card'
        // console.log(document.getElementsByTagName('script')[1].attributes[0].value);
        setTimeout(() => {
            if (button === 0) {
                this.createButton(cant)
            }
        }, 1000);
    }
    async getData(data,st){
        const ordenData = await Wrapper.getData(data)
        // console.log(ordenData);
        if (ordenData !== undefined) {
            this.setState({[st]:ordenData.data.data})
        }
        // this.products()
    }
    finalizar(){
        let pay = JSON.parse(localStorage.getItem('Dpaypal'))
        let data ={
            PaymentMethodID: "1",
            ReferenceCode: pay.id
        }
        // console.log(data);
        // console.log(this.state.ordens);
        Axios.post(Url+'orders/'+this.state.ordens.OrderID+'/buying',data,{withCredentials:true})
        .then(res=>{
            // console.log(res.data.data);
        })
        .catch(err=>{
            console.log(err);
        })
    }
    formaPago(data){
        this.setState({formsurvey:data})
        // this.setState({button:0})
    }
    buttonCgange(btn){
        this.setState({button:btn})
    }
    
    pago(){
        const {formsurvey} = this.state
        switch (formsurvey) {
            case 1:
                this.getPaypal(this.state.metodos[1].client_id,this.state.ordens.OrderCurrency,this.state.ordens.OrderTotalTotal)
                return(
                    <div>
                        <ol>
                            <li>Regístrate en PayPal. Abre tu cuenta PayPal gratis y asocia tus tarjetas de crédito.</li>
                            <li>Paga con PayPal. Busca el botón de PayPal a la hora de pagar y compra fácilmente ingresando tu correo electrónico y contraseña</li>
                            <li>¡Listo!</li>
                        </ol>
                        
                    </div>
                )
            case 2:
                return(
                    <div>
                        <ol>
                            <li>Haz click en el botón verde de "Generar Orden".</li>
                            <li>El sistema te generará un número de referencia</li>
                            <li>Ve al OXXO que gustes y dale el número de referencia al cajero de forma verbal (como recargar saldo en tu cel) y realiza el pago en efectivo. (OXXO te cobrará una comisión al momento, aproximadamente $10 MXN.). NOTA: El cajero de OXXO debe ingresar la referencia en su pantalla inicial, como si fuera el código de cualquier artículo que venda OXXO, no tiene que buscar ningún servicio en su sistema.</li>
                            <li>Listo!</li>
                        </ol>
                    </div>
                    
                )
            case 3:
                return(
                    <FormCD id={this.state.ordens.OrderID} ref={element => {this.child = element}}/>
                )
            case 4:
                return(
                    <ol>
                        <li>Imprime o apunta el numero de refencia y el banco</li>
                        <li>Acude a tu sucursal bancaria de preferencia o realiza el pago desde tu app</li>
                        <li>Descarga o guarda tu voucher de pago</li>
                        <li>Ve al apartado de Mis pedidos</li>
                        <li>Selecciona tu orden de transferencia </li>
                        <li>Llena el formulario y sube tu voucher y espera respuesta</li>
                        <li>!Listo¡ realizaste tu compra por transferencia</li>
                    </ol>
                    
                )
            default:
                break;
        }
    }

    cancelOrder(){
        Axios.post(Url+'orders/'+this.state.ordens.OrderID+'/cancel',{withCredentials:true})
        .then(res=>{
            // console.log(res.data.data);
        })
        .catch(err=>{
            console.log(err);
        })
        // console.log(this.state.ordens.OrderID);
    }
    changeI(data){
        this.setState({principal:data})
    }
    change(data){
        this.setState({view:data})
    }
    products(){
        const {ordens} = this.state
        if (this.state.products.length === 0) {
            let arr = this.state.ordens.OrderItems
            arr = Object.keys(arr)
            this.setState({products:arr})
        }
        if (ordens.PaymentInfo !== undefined) {
            this.setState({oxxo:ordens.PaymentInfo.stub})
        }
    }
    logo(data){
        const {terminos} = this.state
        switch (data) {
            case 'PayPal':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgPaypal} alt="Paypal" className={terminos?'':'mt-30'}/>
                    </span>
                )
            case 'Oxxo':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgOxxo} alt="Oxxo" className={terminos?'':'mt-30'}/>
                    </span>
                )
            case 'Tarjeta de Crédito/Débito':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgVisa} alt="Visa" className={terminos?'':'mt-30'}/>
                    </span>
                )
            case 'Transferencia':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgTrans} alt="Transaccion" className={terminos?'':'mt-30'}/>
                    </span>
                )
            default:
                break;
        }
    }
    realizarPago(){
        const {formsurvey} = this.state
        switch (formsurvey) {
            case 2:
                this.changeI('oxxo')
                break
            case 3:
                this.setState({products:{}})
                this.child.showAlert();
                break
            case 4:
                this.changeI('trans')
                break
            default:
                break;
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;
    
        this.setState({
          terminos: value
        });
      }
    Vprincipal(){
        const {principal} = this.state
        switch (principal) {
            case 'inicial':
                const {terminos} = this.state
                return(
                    <div>
                        <div className="row">
                            <label>
                                <input
                                name="terminos"
                                // className={e.title}
                                type="checkbox"
                                onChange={this.handleInputChange} 
                                disabled={terminos}
                                readOnly={terminos}
                                />
                                <span>Aceptar terminos y condiciones</span>
                            </label>
                        </div>
                        <div className="row">
                            <h6 className="dark-text"><strong>Forma de Pago</strong></h6>
                            <div className="col s4 l4 brs">
                                <form className="form-check form-check-inline center-align">
                                    {
                                        this.state.keyMetodos.map((e,index)=>{
                                            return(
                                                <div key={'metodo'+index}>
                                                    <label>
                                                        {terminos ? 
                                                        <input type="radio" name="inlineRadioOptions" id={"inlineRadio"+index} value={this.state.metodos[e].name} onClick={()=>this.formaPago(this.state.metodos[e].id)}></input>
                                                        :<div></div>}
                                                        {this.logo(this.state.metodos[e].name)}
                                                    </label>
                                                </div>  
                                            )
                                        })
                                    }
                                </form>
                            </div>
                            <div className="col s8 l8">
                                <div className="buy">
                                    {this.pago()}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'oxxo':
                return(
                    <div>
                        <h6 className="text-center">Realiza tu deposito</h6>
                        <Oxxo id={this.state.ordens.OrderID} data={{}}/>
                    </div>
                )
            case 'trans':
                return(
                    <div>
                        <h6>Realiza tu deposito</h6>
                        <Transfer id={this.state.ordens.OrderID} data={{}}/>
                    </div>
                )
            default:
                break;
        }
    }
    clearInfo(){
        localStorage.clear()
    }
    render(){
        // const {products,ordens,formsurvey,principal,nav} = this.state
        const {ordens,formsurvey,principal} = this.state
        if (Object.keys(ordens).length === 0) {
            return(<Loader />)
        }else{
            if(localStorage.getItem('metodos') === null){
                return(
                    <div className="grey lighten-4">
                        {/* <Navbar menu={this.state.nav}/> */}
                        <div className="container hminvh-74">
                            <div className="card p-20 mt-20">
                                <h1>No hay una orden creada</h1>
                            </div>
                        </div>
                        {/* <Foter/> */}
                    </div>
                )
            }
            else{
                // console.log(ordens);
                const {SubOrders} = ordens
                let sub = Object.keys(SubOrders)
                return(
                    <div className="grey lighten-4">
                        {/* <Navbar menu={this.state.nav}/> */}
                            <div className="w-80 m-auto p-20">
                                <h5>Pago de carrito de compras</h5>
                                <div className="row">
                                    <div className="col s12 l8 sp">
                                        <div className="card w-100 p-25">
                                            {this.Vprincipal()}
                                        </div>
                                    </div>
                                    <div className="col s12 l4 stiky-f">
                                        <div className="card w-100 p-20">
                                            <h6 className="dark-text"><strong>Detalles de la compra</strong></h6>
                                            <hr/>
                                            {sub.map((e,index)=>{
                                                // console.log(SubOrders[e].OSellerItems);
                                                let products = Object.keys(SubOrders[e].OSellerItems)
                                                return(
                                                    <div key={"Sub"+index}>
                                                        {/* <p>Sub orden {index+1}</p> */}
                                                        <DetallesOrden orden={SubOrders[e]} productos={products}/>
                                                    </div>
                                                )
                                            })}
                                            {principal === 'oxxo' || principal === 'trans'?
                                            <div>
                                                <Link onClick={this.clearInfo} className="btn outline w-100" to="/">Seguir comprando</Link>
                                                <Link onClick={this.clearInfo} className="btn bg-orange mt-20 w-100" to="/MyOrdes">Ir a tus compras</Link>
                                            </div>:
                                            <div>
                                                <button className={formsurvey === 1 || principal !== 'inicial' ?'oculto':'btn w-100 bg-orange'} onClick={this.realizarPago} disabled={formsurvey === 0?true:false}>REALIZAR COMPRA</button>
                                                <div id="paypal-button-container" className={formsurvey === 1 ?'mt-20':'oculto'}></div>
                                                <button className='btn w-100 btn outline mt-20'>EDITAR PEDIDO</button>
                                                <button className='btn w-100 btn outline mt-20'>CANCELAR PEDIDO</button>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        {/* <Foter/> */}
                    </div>
                )
            }
            // const {subtotal,discount,iva,total} = this.state.listcar
        }
    }
}

export default orden