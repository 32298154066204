import React from "react";
import imgPaypal from "../../img/paypal-logo-png-transparent.png";
import imgOxxo from "../../img/oxxo.png";
import imgVisa from "../../img/visa.png";
import imgTrans from "../../img/deposito.PNG";
import FormCD from "./formCD";
// formsurvey,metodos
class payMethod extends React.Component{
    constructor(){
        super();
        this.state={
            formsurvey:'',
            paypal:0
        }
    }
    buyTDC(){
        this.child.showAlert();
    }
    formaPago(data){
        this.setState({formsurvey:data})
        this.props.selectMB(data)
        if (data === 1) {
            this.props.btnBuy('0')
        }else{
            this.props.btnBuy('1')
        }
    }
    pago(){
        const {formsurvey} = this.state
        const {metodos,ordens} = this.props
        switch (formsurvey) {
            case 1:
                this.getPaypal(metodos[1].client_id,ordens.OrderCurrency,ordens.OrderTotalTotal)
                return(
                    <div>
                        <ol>
                            <li>Regístrate en PayPal. Abre tu cuenta PayPal gratis y asocia tus tarjetas de crédito.</li>
                            <li>Paga con PayPal. Busca el botón de PayPal a la hora de pagar y compra fácilmente ingresando tu correo electrónico y contraseña</li>
                            <li>¡Listo!</li>
                        </ol>
                        
                    </div>
                )
            case 2:
                return(
                    <div>
                        <ol>
                            <li>Haz click en el botón verde de "Generar Orden".</li>
                            <li>El sistema te generará un número de referencia</li>
                            <li>Ve al OXXO que gustes y dale el número de referencia al cajero de forma verbal (como recargar saldo en tu cel) y realiza el pago en efectivo. (OXXO te cobrará una comisión al momento, aproximadamente $10 MXN.). NOTA: El cajero de OXXO debe ingresar la referencia en su pantalla inicial, como si fuera el código de cualquier artículo que venda OXXO, no tiene que buscar ningún servicio en su sistema.</li>
                            <li>Listo!</li>
                        </ol>
                    </div>
                    
                )
            case 3:
                return(
                    <FormCD id={this.props.ordens.OrderID} ref={element => {this.child = element}}/>
                )
            case 4:
                return(
                    <ol>
                        <li>Imprime o apunta el numero de refencia y el banco</li>
                        <li>Acude a tu sucursal bancaria de preferencia o realiza el pago desde tu app</li>
                        <li>Descarga o guarda tu bauche de pago</li>
                        <li>Ve al apartado de Mis pedidos</li>
                        <li>Selecciona tu orden de transferencia </li>
                        <li>Llena el formulario y sube tu bauche y espera respuesta</li>
                        <li>!Listo¡ realizaste tu compra por transferencia</li>
                    </ol>
                    
                )
            default:
                break;
        }
    }
    logo(data){
        switch (data) {
            case 'PayPal':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgPaypal} alt="Paypal"/>
                    </span>
                )
            case 'Oxxo':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgOxxo} alt="Oxxo"/>
                    </span>
                )
            case 'Tarjeta de Crédito/Débito':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgVisa} alt="Visa"/>
                    </span>
                )
            case 'Transferencia':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgTrans} alt="Transaccion"/>
                    </span>
                )
            default:
                break;
        }
    }
    changeVista(id){
        this.props.formaPago(id)
    }
    getPaypal(idClient,curren,cant){
        document.getElementsByTagName('script')[1].attributes[0].value = 'https://www.paypal.com/sdk/js?client-id='+idClient+'&currency='+curren+'&disable-funding=credit,card'
        // console.log(document.getElementsByTagName('script')[1].attributes[0].value);
        setTimeout(() => {
            this.createButton(cant)
        }, 1000);
    }
    createButton(cant){
        const {ordens} = this.props
        const {paypal} = this.state
        let pay = document.querySelector('#paypal-button-container')
        if (pay !== null && paypal === 0) {
            window.globalMia(cant,ordens.OrderID)
            this.setState({paypal:1})
        }
    }
    render(){
        const {keyMetodos,metodos} = this.props
        // console.log(metodos);
        return(
            <div>
                <h6 className="dark-text"><strong>Forma de Pagos</strong></h6>
                <div className="row">
                    <div className="col s4 l4 brs">
                        <form className="form-check form-check-inline center-align">
                            {
                                keyMetodos.map((e,index)=>{
                                    // console.log(metodos[e])
                                    return(
                                        <div key={'metodo'+index}>
                                            <label>
                                                <input type="radio" name="inlineRadioOptions" id={"inlineRadio"+index} value={metodos[e].name} onClick={()=>this.formaPago(metodos[e].id)}></input>
                                                {this.logo(metodos[e].name)}
                                                {/* <span className="ml-2">{metodos[e].name}</span> */}
                                            </label>
                                        </div>  
                                    )
                                })
                            }
                        </form>
                    </div>
                    <div className="col s8 l8">
                        <div className="buy">
                            {this.pago()}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default payMethod