import React from "react";
import Router from '../router/router';
import {messageService} from "../hooks/global";
import CarContext from "../hooks/car";
import Functions from "../hooks/functions";
// import Wrapper from "../hooks/wrapper";
import 'materialize-css/dist/css/materialize.min.css';

class principal extends React.Component{
    constructor() {
        super();
        this.state= {
            messages:{
                text:'',
            },
            menuP:{},
            alerta:false,
            dataAlert:{},
            cantP:0
        }
        this.activeA = this.activeA.bind(this);
    }
    async componentDidMount() {
        const menu = await Functions.user()
        if (menu === undefined) {
            window.location.reload()
        }else{
            this.setState({menuP:menu})
        }
        // subscribe to home component messages
        this.subscription = messageService.getMessage().subscribe(message => {
            if (message) {
                if (message.typeAlerta !== '' && message.typeAlerta !== undefined) {
                    let keys = Object.keys(message.text).length
                    this.setState({dataAlert:message.alerta})
                    this.setState({cantP:keys})
                    this.setState({alerta:true})
                }
                // add message to local state if not empty
                this.setState({ messages: message });
            } else {
                // clear messages when empty message received
                this.setState({ messages: [] });
            }
        });
    }

    componentWillUnmount() {
        // unsubscribe to ensure no memory leaks
        if (this.state.menuP !== undefined) {
            this.subscription.unsubscribe();
        }
    }
    activeA(){
        messageService.sendMessage('','typeAlerta');
        this.setState({alerta:false})
    }
    render(){
        if (this.state.menuP !== undefined) {
            const {alerta, dataAlert, cantP} = this.state
            return(
                // <CarContext.Provider value={this.state.messages.text} breadcrum={}>
                <CarContext.Provider value={this.state.messages.text} >
                    <Router data={this.state.menuP} Aalert={alerta} dataA={dataAlert} cantP={cantP} activeA={this.activeA}/>
                </CarContext.Provider>
            )
            // if (Object.keys(this.state.menu).length>0) {
            // }
        }else{
            return(<div></div>)
        }
        // if (this.state.messages !== undefined) {
        // }
    }
}
export default principal