import React from "react";
// import { Link } from "react-router-dom";
import {messageService} from "../hooks/global";
import Axios from "axios";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";
import Functions from "../hooks/functions";

class buyCurses extends React.Component{
    constructor() {
        super();
        this.state= {
            cartItems:{},
        }
        this.getcart = this.getcart.bind(this);
    }
    async getcart(){
        const cart = await Wrapper.getData(Url+'cart')
        console.log('carrito');
        console.log(cart);
        this.setState({cartItems:cart.data.data})
        this.addCar()
    }
    buyCart(url){
        let data = {}
        data.item_id = this.props.data.CourseID
        data.item_ty = ''
        console.log(url);
        console.log(data);
        Axios.post(Url+url,data,{withCredentials:true})
        .then(res=>{
            console.log(res);
            messageService.sendMessage(res.data.data.cart.items);
        })
        .catch(err=>{
            if (err.response.data.error.repeated) {
                this.setState({alerta:true})
            }
        })
    }
    alerta(){
        const {alerta} = this.state
        if (alerta) {
            setTimeout(() => {
                this.setState({alerta:false})
            }, 3100);        
            return(
                <div className="alerta">El producto ya esta agregado</div>
            )
        }
    }
    addCar(){
        const {cartItems} = this.state
        let invitado = localStorage.getItem('invitado')
        console.log('buy curses');
        console.log(cartItems);
        if (cartItems === null) {
            if(invitado !== null){
                this.buyCart('cart/'+ invitado)
            }else{
                this.buyCart('cart/add')
            }
        }else{
            this.buyCart('cart/'+cartItems.id)
        }
    }
    buyDirecta(data) {
        console.log('directa');
        console.log(data);
        let invitado = localStorage.getItem('invitado')
        if (invitado !== null) {
            window.location.href = 'https://accounts.xpertcad.com/?APPID=4'
        }else{
            Axios.post(Url+'orders/buyNow/'+data,false,{withCredentials:true})
            .then(res=>{
                // console.log(res);
                localStorage.setItem('orden',JSON.stringify(res.data.data.Order))
                localStorage.setItem('metodos',JSON.stringify(res.data.data.PaymentMethods))
                window.location.href = '/checkout'
            })
            .catch(err=>{
                console.log(err.response);
            })
        }
    }
  
    render(){
        const { CourseImage,CourseDescriptionShort,CoursePrice,CourseCurrency,CourseCategory,CourseID,CourseTags } = this.props.data
        if (CourseTags !== '') {
            var tags = CourseTags
        }
        return(
            <div className={"grey lighten-4 card "+this.props.position}>
                {this.alerta()}
                <div className="card-image card-ins">
                    <img className="tumbail" src={CourseImage} alt="curso"/>
                </div>
                <div className="card-stacked p-10">
                    <div className="card-content scontenido">
                        {CourseCategory !== ''?
                        <p className="centrar"><i className="material-icons-outlined text-orange">category</i> <span className="ml-10">{CourseCategory}</span></p>
                        :<div></div>}
                        {tags !== undefined && tags.length > 0?
                        <div className="mt-10 centrar">
                            <i className="material-icons-outlined text-orange">label</i>
                            {tags !== undefined?
                            tags.map((e,index)=>{
                                return(
                                    <span className="ml-5 tag grey lighten-2 grey-text text-darken-1" key={"tag"+index}>{e}</span>
                                )
                            })
                            :<div></div>}
                        </div>
                        :<div></div>}
                    </div>
                        <div className="center-align">{CourseDescriptionShort}</div>
                        <h5 className="center-align mt-20"><strong>{Functions.formatPrice(CoursePrice)} {CourseCurrency}</strong></h5>
                    <div className="card-action mt-20 sp mb-20">
                        <button className="waves-effect btn outline w-100" onClick={this.getcart}>
                            AGREGAR AL CARRITO
                        </button>
                        <div className="waves-effect btn bg-orange w-100 mt-20" onClick={()=>this.buyDirecta(CourseID)}>
                            COMPRAR AHORA
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default buyCurses