import React from "react";
import Url from "../hooks/url";
import Functions from "../hooks/functions";
import Wrapper from "../hooks/wrapper";

class changeCurrency extends React.Component{
    constructor(){
        super();
        this.state={
            currencys:{},
            keys:[],
            curren:''
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        this.getCurrencys()
        this.getCurren()
    }
    async getCurren(){
        // let currency = localStorage.getItem('currency')
        // if (currency === null) {
        const currency = await Wrapper.getData(Url+'currency')
        // console.log('obtener moneda');
        // console.log(currency);
        if (currency !== undefined) {
            this.setState({curren:currency.data.data})
            localStorage.setItem('currency',currency.data.data)
        }
            
        // }else{
        //     this.setState({curren:currency})
        // }
    }
    async getCurrencys(){
        const currency = await Wrapper.getData(Url+'currency/all')
        this.setState({currencys:currency.data.data})
        this.setState({keys:Functions.getKeys(currency.data.data)})
    }
    async handleChange(event) {
        this.setState({curren: event.target.value});
        const currency = await Wrapper.postData(Url+'currency/'+event.target.value)
        console.log(currency);
        // console.log(event.target.value);
        window.location.reload()
    }

    render(){
        const {keys,currencys, curren} = this.state
        if(keys.length>0){
            return(
                <div className="input-field ml-auto">
                    <select className="bn" value={curren} onChange={this.handleChange}>
                        {keys.map((e,index)=>{
                            return(
                                // <option selected={currencys[e].Code === curren} value={currencys[e].Code} key={"options"+index}>{currencys[e].Code}</option>
                                <option value={currencys[e].Code} key={"options"+index}>{currencys[e].Code}</option>
                            )
                        })}
                    </select>
                </div>
            )
        }else{
            return(<div></div>)
        }
    }
}
export default changeCurrency