import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import Wrapper from "../hooks/wrapper";
import {messageService} from "../hooks/global";

import Loader from "../components/loader";
import Tablas from "../components/tablas";

class listOrdes extends React.Component {
    constructor() {
        super();
        this.state= {
            data:{},
            nav:{},
            key:[],
            vista:'lista',
            single:{},
            ordenData:{},
            carga:'0',
            btn:'1',
            vct:'metodos',
            selectMetodoCompra:0
        }
        this.changeVista = this.changeVista.bind(this);
    }
    componentDidMount(){
        messageService.sendMessage('Tienda,/ > Mis pedidos','bread')
        this.setState({nav:Apis.navbar})
        this.getData(Url+'orders','data')
    }
    getKey(){
        let keys = Object.keys(this.state.data)
        this.setState({key:keys})
    }
    async getData(data,st){
        const orders = await Wrapper.getData(data)
        this.setState({[st]:orders.data.data.Orders})
        this.getKey()
    }
    
    changeVista(vista,data){
        this.setState({carga:'0'})
        this.setState({single:data})
        this.setState({vista:vista})
    }
    vista(){
        const {vista,data,key} = this.state
        switch (vista) {
            case 'lista':
                let headers = ['Nombre','Correo','Telefono','Fecha y hora','Cantidad','Status','Acciones']
                if (key.length === 0) {
                    return(
                        <div>
                            <p>Inicio / Usuario / Órdenes de compra</p>
                            <h5>Órdenes de compra</h5>
                            <div className='card p-4 text-center'>
                                <h1 className="black-text">No hay ordenes disponibles</h1>
                            </div>
                        </div>
                    )
                }else{
                    return(
                        <div>
                            <h5>Mis pedidos</h5>
                            <Tablas headers={headers} keyList={key} data={data} changeVista={this.changeVista} vista='lista'/>
                            {/* <div className='card'>
                            </div> */}
                        </div>
                    )
                }
            default:
                break;
        }
    }
    render(){
        const {nav} = this.state
        if (Object.keys(nav).length === 0) {
            return (<Loader />)
        }else{
            // const {others,featured} = this.state.data.courses
            return(
                <div className="grey lighten-4">
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="w-80 m-auto hminvh-75">
                        {this.vista()}
                    </div>
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}
export default listOrdes