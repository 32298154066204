import React from "react";
import Axios from "axios";
import Url from "../../hooks/url";
import Logo from "../../img/Xpertcad.png";
import Functions from "../../hooks/functions";

class transfer extends React.Component{
    constructor(){
        super();
        this.state = {
            dataTransfer:{}
        }
        this.getData = this.getData.bind(this);
    }
    componentDidMount(){
        const {data} = this.props
        if (Object.keys(data).length > 0) {
            this.setState({dataTransfer:data})
        }else{
            this.getData()
        }
    }
    getData(){
        const {id} = this.props
        let data = {
            PaymentMethodID: "4"
        }
        Axios.post(Url+'orders/'+id+'/buying',data,{withCredentials:true})
        .then(res=>{
            // console.log(res.data.data.payment_ticket);
            this.setState({dataTransfer:res.data.data.payment_ticket})
        })
        .catch(err=>{
            console.log(err.response);
        })
    }
    render(){
        const {dataTransfer} = this.state
        if (Object.keys(this.state.dataTransfer).length === 0) {
            return(
                <div></div>
            )
        }
        else{
            const {Amount,Currency,CLABE,Reference,Bank} = dataTransfer
            return(
                <div className="card p-20" id="factura">
                    <div className="text-center">
                        <img src={Logo} alt="logo"/>
                        <hr/>
                        <h2>Monto a pagar</h2>
                        <h3 className="red-text">{Functions.formatPrice(Amount)} {Currency}</h3>
                    </div>
                    <strong>Desde BBVA Bancomer</strong>
                    <p>Dentro del menu "Pagar" selecciona la opción "De Servicios" e ingresa los siguientes
                    datos:</p>
                    <div className="grey lighten-3 p-20 text-center">
                        <div><strong>Clabe: </strong>{CLABE}</div>
                        <div><strong>Referencia: </strong>{Reference}</div>
                        <div><strong>Concepto: </strong>Compra de curso</div>
                    </div>
                    <strong>Desde cualquier otro banco</strong>
                    <p>Ingresa a la sección de transferencias o pagos a otros bancos e ingresa los siguientes datos:</p>
                    <div className="grey lighten-3 p-20 text-center">
                        <div><strong>Beneficiario: </strong>XpertCAD</div>
                        <div><strong>Banco destino: </strong>{Bank}</div>
                        <div><strong>Clabe: </strong>{CLABE}</div>
                        <div><strong>Referencia: </strong>{Reference}</div>
                        <div><strong>Concepto: </strong>Compra de curso</div>
                    </div>
                    <strong>¿Como realizar el pago?</strong>
                    <p>1. SPEI ® es un sistema de transferencias electrónicas, a través de la banca por
                    internet o de la banca movíl. Para realizar tu pago es necesario utilizar la CLABE
                    informada para dar de alta la Cuenta Destino.</p>
                    <p>2. Si al dar de alta la cuenta necesitas un nombre de banco destino, puedes
                    ingresar: BBVA Bancomer.</p>
                    <p>3. Los datos de este ticket solo son validos para este pedido y podrá ser utilizada
                    una sola vez, por lo que el pago debe efectuarse por el monto exacto de la
                    compra. Si efectúa por un monto diferente al indicado, éste será rechazado de
                    manera automática.</p>
                    <p>4. Tienes hasta la fecha de vencimiento indicada para realizar el pago.</p>
                    <p>5. Te recordamos que es necesario conservar el comprobante de pago original, el
                    cual deberá coincidir con el monto total de tu compra, en caso contrario,
                    DISTRIBUIDORA LIVERPOOL, S.A. DE C.V. se reserva el derecho de realizar la
                    entrega hasta en tanto no le sea acreditado el pago correspondiente.</p>
                </div>
            )
        }
    }
}
export default transfer