import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShoppingCart,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";

function checkIn() {
    async function handleClick() {
        const cartId = await Wrapper.getData(Url+'cart')
        const cart = await Wrapper.getData(Url+'cart/'+cartId.data.data.id)
        console.log(cart);
        Axios.post(Url+'check-in',{withCredentials:true})
        .then(res=>{
            if (cart.data.data !== null) {
                if (cart.data.data.cart.items !== undefined) {
                    window.location.href = '/Mycar'
                }else{
                    window.location.href = '/'
                }
            }else{
                window.location.href = '/'
            }
        })
        .catch(err=>{
            console.log(err.response);
            // window.location.href = '/'   
        })
    }
  
    return (
        <div>
            {handleClick()}
        </div>
    );
  }

export default checkIn