import React from "react";
import { Link } from "react-router-dom";
import Url from "../../hooks/url";
import Wrapper from "../../hooks/wrapper";
import {messageService} from "../../hooks/global";
import Loader from "../loader";
import PopFactura from "./popFactura";

class singlePaymant extends React.Component{
    constructor(){
        super();
        this.state={
            id:'',
            pago:{},
            pop:false,
            vista:''
        }
        this.activePop = this.activePop.bind(this);
        this.getInicial = this.getInicial.bind(this);
    }
    componentDidMount(){
        this.getInicial()
    }
    getInicial(){
        let url = window.location.pathname
        url = url.split('/')
        this.setState({vista:url[2]})
        url = url[3]
        messageService.sendMessage('Tienda,/ > Autor > Pagos,/Author/payment > Pago #'+url,'bread')
        this.setState({id:url})
        this.getData(url)
    }
    async getData(id){
        const orderSeller = await Wrapper.getData(Url+'orders_sellers/'+id+'/payment')
        this.setState({pago:orderSeller.data.data.SPaymentInfo})
    }
    activePop(data){
        this.setState({pop:data})
    }
    pop(){
        if (this.state.pop) {
            return(<PopFactura activePop={this.activePop} id={this.state.id} getInicial={this.getInicial} type="payment"/>)
        }
    }
    render(){
        const {id,pago} = this.state
        // console.log(pago);
        if (Object.keys(pago).length === 0) {
            return(<Loader/>)
        } else {
            return(
                <div>
                    {/* <Navbar menu={Apis.navbar}/> */}
                    {this.pop()}
                    <div className="w-90 m-auto hminvh-77">
                        <p><Link to="/">Inicio</Link> / Autor / <Link to="/author/payment">Mis Pagos</Link> /Pago #{id}</p>
                        <div className="row">
                            <div className="col s6 l6">
                                <h5>Pago #{id}</h5>
                            </div>
                            <div className="col s6 l6 text-right">
                                <div><span className={pago.SPaymentStatusName === 'Done'?"estado green accent-2 white-text pl-5 pr-5":pago.SPaymentStatusName === 'New'?"estado blue darken-3 white-text pl-5 pr-5":pago.SPaymentStatusName === 'Pending'?"estado grey lighten-1 white-text pl-5 pr-5":pago.SPaymentStatusName === 'Checking'?'estado bg-orange white-text pl-5 pr-5':''}>{pago.SPaymentStatusName}</span></div>
                                <div><small>Realizada el: {pago.SPaymentDate}</small></div>
                            </div>
                        </div>
                        <div className="w-100 card p-20">
                            <strong>Detalles del pago</strong>
                            <hr/>
                            <p><strong>Ganancia: </strong><span>{pago.SPaymentTotal}</span></p>
                            <p><strong>Comisión: </strong><span>{pago.SPaymentFee}</span></p>
                            <p><strong>Total: </strong><span>{pago.SPaymentSales}</span></p>
                            {pago.SPaymentStatusName !== 'Pending'?
                            <div></div>:
                            <div className="text-right">
                                <button className="btn grey darken-4" onClick={()=>this.activePop(true)}>Subir factura</button>
                            </div>
                            }
                        </div>
                        {pago.SPaymentStatusName !== 'Pending'?
                        <div className="w-100 card p-20">
                            <div className="row">
                                <div className="col s8 l8"><strong>Detalles de la factura</strong></div>
                                <div className="col s4 l4 text-right">
                                    <small>Realizada el: {pago.SPaymentInvoiceDate}</small>
                                </div>
                            </div>
                            <hr/>
                            <p><strong>Folio fiscal: </strong><span>{pago.SPaymentInvoiceUID}</span></p>
                            <p><strong>Total de factura: </strong><span>{pago.SPaymentInvoiceTotal}</span></p>
                            <p><strong>XML de fatura: </strong></p>
                            <iframe src={pago.SPaymentInvoiceXMLUrl} title="XML" className="w-100"/> 
                        </div>
                        :<div></div>}
                    </div>
                    {/* <Foter/> */}
                </div>
            )
        }
    }
}

export default singlePaymant