import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes, faVolumeOff, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Url from "../hooks/url";
import Functions from "../hooks/functions";
import { Link } from "react-router-dom";
class product extends React.Component{
    deleteCart(idcart,idproduct){
        Axios.delete(Url+'cart/'+idcart+'/'+idproduct)
        .then(res=>{
            this.props.getCart(idcart)
            this.props.getKeys(true)
        })
        .catch(err=>{
            // this.props.getCart(idcart)
            this.props.getKeys(true)
            console.log(err.repose);
        })
    }
    close(){
        const { cart_id,id } = this.props.data
        if (this.props.getCart !== undefined) {
            return(
                <div className="col s1 l1">
                    <div className="close red-text" onClick={()=>this.deleteCart(cart_id,id)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            )
        }
    }
    render(){
        if (this.props.data !== undefined) {
            let ordenes = this.props.data.item_info
            // console.log(ordenes);
            if (ordenes !== undefined) {
                var { price,currency,promo_discount,OItemTotalDiscount,OItemTotalTotal,OItemCurrency,item_info,item_id } = this.props.data
            }else{
                var { OSellerItemCurrency,OSellerItemTotalTotal,OSellerItemInfo,OSellerItemDescription,OSellerItemTotalAmount,OSellerItemTotalTax } = this.props.data
            }
            let objeto = {}
            if (item_info !== undefined) {
                objeto = item_info
                var { item_thumbnail,item_level,item_name,item_category } = objeto
            }else{
                objeto = OSellerItemInfo
                if (OSellerItemInfo !== undefined) {
                    var { OSItemThumbnail,OSItemID } = objeto
                }
            }
            // console.log(this.props.data);
            return(
                <div className="row centrar">
                    <div className="col s3 l3">
                        {ordenes !== undefined ? 
                        <img className="w-100" src={item_thumbnail} alt="class" />:
                        <img className="w-100" src={OSItemThumbnail} alt="class" />
                        }
                    </div>
                    <div className="col s6 l6">
                        <div className="data">
                            {/* <strong>{title}{OItemDescription}</strong> */}
                            {item_id === undefined?
                            <Link to={'/Courses/'+OSItemID}>
                                <strong className="sm">{OSellerItemDescription}</strong>
                            </Link>:
                            <Link to={'/Courses/'+item_id}>
                                <strong className="sm">{item_name}</strong>
                            </Link>}
                            {/* <Link to={'/Courses/'+item_id}>
                                <strong className="sm">{description}</strong>
                            </Link> */}
                            {/* <p className="sm">{item_short_description}</p> */}
                            {parseInt(promo_discount)>0?
                            <p className="sm red-text">Descuento de: {promo_discount} {OItemTotalDiscount}</p>
                            :<div></div>}
                            {ordenes !== undefined?
                                <div>
                                    <p className="sm mt-10">
                                        <small>
                                            <strong>Nivel del curso:</strong>
                                            <span className="ml-5">{item_level}</span>
                                        </small>
                                    </p>
                                    <p className="sm">
                                        <small>
                                            <strong>Categorias:</strong>
                                            <span className="ml-5">{item_category}</span>
                                        </small>
                                    </p>
                                </div>
                            :<div></div>}
                                {/* <FontAwesomeIcon icon={faVolumeOff}/>
                                {'Español'}
                                <FontAwesomeIcon icon={faClosedCaptioning} className="ml-10"/>
                                {'Español, Ingles, Portuges'} */}
                        </div>
                    </div>
                    <div className="col s3 l3 right-align">
                        {ordenes !== undefined?
                        <p className="sm">{OItemTotalTotal === undefined?Functions.formatPrice(price):Functions.formatPrice(OItemTotalTotal)} {currency}{OItemCurrency}</p>
                        :<div>
                            <small>Subtotal: {Functions.formatPrice(OSellerItemTotalAmount)} {OSellerItemCurrency}</small><br/>
                            <small>IVA: {Functions.formatPrice(OSellerItemTotalTax)} {OSellerItemCurrency}</small><br/>
                            <small>Total: {Functions.formatPrice(OSellerItemTotalTotal)} {OSellerItemCurrency}</small>
                        </div>}
                    </div>
                    {this.close()}
                </div>
            )
        }else{
            return(<div></div>)
        }
    }
}
export default product