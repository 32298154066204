import Axios from "axios";
const wrapper = {
    configAxios(cookie){
        // console.log(cookie);
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookie;
    },
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        // console.log(ca);
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    },
    async getData(request){ 
        try {
            var res = {}
            if (window.location.host === 'localhost:3000') {
                console.log(request);
                res = await Axios.get(request)
                .catch(error=>{
                    console.log(error.reponse);
                })
            }else{
                res = await Axios.get(request,{withCredentials:true})
            }
            // console.log(res);
            return await res
        } catch (error) {
            console.log(error.reponse);
        }
    },
    async postData(request,params){
        try {
            const res = await Axios.post(request,params,{withCredentials:true})
            .catch(error=>{
                console.log(error.reponse);
            })
            return await res
        } catch (error) {
            console.log(error.reponse);
        }
    },
}

export default wrapper