import React from "react";
import "../styles/styles.scss";
import NavLeft from "./navLeft";
import { Link } from "react-router-dom";
import {messageService} from "../hooks/global";
import CarContext from "../hooks/car";
import Wrapper from "../hooks/wrapper";
import Functions from "../hooks/functions";
import Url from "../hooks/url";
import img from "../img/X-xpertcad3.png";
import Loader from "../components/loader";
import ChangeCurrency from "../components/changeCurrency";

class navbar extends React.Component {
    constructor() {
        super();
        this.state= {
            active:false,
            loader:true,
            breadcrumb:{},
            dataUser:{},
            cantidad:0,
            ordenes:{},
            compra:0,
            newCompra:false,
            keys:[],
            id:'',
            datos:0,
            contador:0
        }
        this.getUser = this.getUser.bind(this);
    }
    componentDidMount() {
        // let url = window.location.pathname
        // url = url.split('/')
        // url = url[1]
        // this.setState({breadcrumb:url})
        this.getcart()
        let invitado = localStorage.getItem('invitado')
        if (invitado === null) {
            this.getUser() 
        }
        this.getOrdes()
        this.subscription = messageService.getMessage().subscribe(message => {
            if (message) {
                let pedido = this.state.compra
                pedido = pedido+1
                if (message.text !== undefined) {
                    this.bread(message.breadcrumb)
                    // this.setState({breadcrumb:message.breadcrumb})

                    this.setState({keys:Object.keys(message.text)})
                    if (this.state.cantidad !== Object.keys(message.text).length) {
                        if (this.state.compra > 0) {
                            this.seguir(false)
                        }
                        this.animacionC()
                    }
                    this.setState({compra:pedido})
                    this.setState({ messages: message });
                    this.setState({cantidad:Object.keys(message.text).length})
                }
            } else {
                this.setState({ messages: [] });
            }
        });
    }

    componentWillUnmount() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
        // this.insertar()
    }
    insertar(){
        if (this.props.laterales !== null && this.state.contador === 0) {
            this.setState({contador:1})
            if (Object.keys(this.state.ordenes).length > 0 && Object.keys(this.props.laterales).length > 0) {
                let ordenesM  = {Module: "Mis pedidos", ModuleClass: "/MyOrdes", Icon: "assignment",link:true}
                this.props.laterales.top_menu.splice(2,0,ordenesM)
                this.setState({contador:1})
            }
        }
    }
    seguir(data){
        this.setState({newCompra:data})
    }
    async getOrdes(){
        const orders = await Wrapper.getData(Url+'orders')
        if (orders !== undefined) {
            this.setState({ordenes:orders.data.data.Orders})
        }
    }
    animacionC(){
        let count = document.querySelectorAll('.count')
        if (count[1] !== undefined) {
            count[1].classList.add('countAdd')
            setTimeout(() => {
                count[1].classList.remove('countAdd')
            }, 2000);
        }
    }
    async getUser(){
        const user = await Wrapper.getData(Url+'users')
        // console.log('user');
        // console.log(user);
        if (user !== undefined) {
            if (user.data.data.user_full_name === 'Invitado') {
                localStorage.setItem('invitado',user.data.data.guest_id)
            }
            this.setState({dataUser:user.data.data})
        }
    }
    async addCart(id){
        const cart = await Wrapper.getData(Url+'cart/'+id)
        // console.log('cart');
        // console.log(cart);
        if (cart !== undefined) {
            // console.log(cart);
            if (cart.data.data !== null) {
                if (cart.data.data.cart !== undefined) {
                    if (cart.data.data.cart.items === undefined) {
                        messageService.sendMessage({},'Cproducto');
                        
                    }else{
                        messageService.sendMessage(cart.data.data.cart.items,'Cproducto');
                    }
                }
            }
        }
    }
    async getcart(){
        let invitado = localStorage.getItem('invitado')
        if (invitado !== null) {
            // console.log(invitado);
            this.addCart(invitado)
            this.setState({id:invitado})
        }else{
            const cart = await Wrapper.getData(Url+'cart')
            if (cart !== undefined) {
                // console.log(cart);
                if (cart.data.data !== null) {
                    if (Object.keys(cart.data.data).length >0) {
                        this.addCart(cart.data.data.id)
                        this.setState({id:cart.data.data.id})
                    }
                }
            }
        }
    }
    lateralActive = (data)=> {
        this.setState({active:data})
    }
    Lateral(){
        // console.log(this.props);
        if (this.state.active) {
            return <NavLeft lateralActive={this.lateralActive} list={this.props.menu} rol={this.state.dataUser} left={this.props.laterales.left_menu}/>
        }
    }
    drop(){
        let drop = document.getElementById('dropdown1')
        let fondo = document.querySelector('.dropActive')
        // console.log(drop);
        if (drop !== null) {
            fondo.classList.toggle('oculto')
            drop.classList.toggle('oculto')
            drop.classList.toggle('dropdown-content')
        }
    }
    bread(url){
        if (url !== undefined) {
            let vista = url.split(' > ')
            let links = []
            let bread = []
            vista.forEach(e => {
                let link = e.split(',')
                bread.push(link[0])
                if (link[1]!== undefined) {
                    links.push(link[1])
                }else{
                    links.push('')
                }
            });
            let breac = {}
            breac.links = links
            breac.bread = bread
            this.setState({breadcrumb:breac})
        }
    }

    compra(){
        const {keys,newCompra} = this.state
        if (keys.length > 0 && newCompra) {
            let id = localStorage.getItem('compra')
            let seleccion = 0
            keys.forEach((e,index) => {
                if (this.context[e] !== undefined) {
                    if (this.context[e].item_id === id) {
                        // console.log(index);
                        seleccion = index
                    }
                }
            });
            // const {item_id} = this.context[keys[seleccion]]
            if (this.context[keys[seleccion]] !== undefined) {
                const {item_thumbnail,item_name} = this.context[keys[seleccion]].item_info
                return(
                    <div className="row newBuy">
                        <div className="col s12 l4"></div>
                        <div className="col s6 l4"></div>
                        <div className="col s6 l4">
                            <div className="card">
                                <div className="row sm">
                                    <div className="col s4 sp">
                                        <img src={item_thumbnail} className="w-100 imgBuy" alt="newBuy"/>
                                    </div>
                                    <div className="col s8">
                                        {/* <p><Link to={"/Courses/"+item_id}>{item_name}</Link> fue agregado </p> */}
                                        <p className="sm">{item_name} fue agregado </p>
                                        <p>{keys.length} Items en cart</p>
                                        <div className="centrar mb-10 p-10">
                                            <button className="btn bg-orange" onClick={()=>this.seguir(false)}><span className="fz-9">Seguir comprando</span></button>
                                            <Link to='/Mycar' className="btn grey darken-4 ml-10 fz-9" onClick={()=>this.seguir(false)}><span>Finalizar compra</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                
            }
        }
    }
    render(){
        if (Object.keys(this.props.laterales).length > 0) {
            this.insertar()
        }
        if (this.context === undefined && Object.keys(this.state.breadcrumb).length === 0 && Object.keys(this.state.dataUser).length === 0 && this.props.laterales !== null) {
        // if (Object.keys(this.state.dataUser).length === 0) {
            return(<Loader/>)
        }
        else{
            const { dataUser,breadcrumb } = this.state
            const { avatar } = this.props.menu.user
            return(
                <div className="stiky nav-s">
                    <div className="dropActive oculto" onClick={this.drop}></div>
                    {this.props.laterales !== null && this.props.laterales.top_menu !== 'Unable to get user top menu.' && Object.keys(this.props.laterales).length>0?
                        <ul id="dropdown1" className="oculto">
                        {Functions.formatArray(this.props.laterales.top_menu).map((e,index)=>{
                            return(
                                <li key={"items-menu"+index} onClick={this.drop}> 
                                    {e.link !== undefined?
                                    <Link to={e.ModuleClass} className="black-text">
                                        <i className="material-icons-outlined">{e.Icon}</i> 
                                        <span>{e.Module}</span> 
                                    </Link>
                                    :<a href={e.ModuleClass} className="black-text">
                                        <i className="material-icons-outlined">{e.Icon}</i> 
                                        <span>{e.Module}</span> 
                                    </a>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                    :<div></div>}
                    <nav className="white">
                        <div className="nav-wrapper">
                            <div className="w-100 m-auto">
                                <div className="row">
                                    <div className="col s4 l4 sp">
                                        {Object.keys(dataUser).length === 0 || dataUser.user_full_name === 'Invitado'?
                                        <div></div>:
                                        <div onClick={()=>this.lateralActive(true)} className="grey-text text-darken-3 ml-11 mt-1 pointer w-max">
                                            <i className="material-icons black-text">menu</i>
                                        </div>
                                        }
                                    </div>
                                    <div className="col s4 l4 center-align centrar h-100">
                                        <Link className="logo" to="/">
                                            <img className="w-90 m-auto centrar h-100" src={img} alt="Logo"/>
                                        </Link>
                                    </div>
                                    <div className="col s4 l4 right-align centrar sp">
                                        <ChangeCurrency className="ml-auto"/>
                                        {Object.keys(dataUser).length === 0 || dataUser.user_full_name === 'Invitado'?<div></div>:
                                        <div className="cuadro centrar">
                                            <Link className="cart ml-15 mr-15" to="/Mycar">
                                                <i className="material-icons black-text">notifications_none</i>
                                                <div className="count center-align">0</div>
                                            </Link>
                                        </div>
                                        }
                                        <div className="cuadro centrar">
                                            <Link className="cart ml-15 mr-15" to="/Mycar">
                                                <i className="material-icons black-text">shopping_cart</i>
                                                {/* {console.log('this.context')}
                                                {console.log(this.context)} */}
                                                <div className="count center-align">{Object.keys(this.context).length === 0?0:Object.keys(this.context).length}</div>
                                                {/* <div className="count center-align">{this.state.contador}</div> */}
                                                {/* <div className="count center-align">{this.context}</div> */}
                                            </Link>
                                        </div>
                                        {Object.keys(dataUser).length === 0 || dataUser.user_full_name === 'Invitado'?
                                        <a href="https://accounts.xpertcad.com/?APPID=4" className="btn bg-orange ml-10 mr-10">Iniciar sesión</a>:
                                        <div className="avatar">
                                            <div className="cuadro centrar pointer" onClick={this.drop}>
                                                <div className="ml-20 cirNav">
                                                    <img className="w-95 m-auto centrar h-100" src={avatar} alt="Logo"/>
                                                    <div className="tooltip-left grey darken-4">
                                                        {dataUser.user_full_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {this.Lateral()}
                    {this.compra()}
                    <div className="breadcrumb-div">
                        {Object.keys(breadcrumb).length>0?
                        breadcrumb.bread.map((e,index)=>{
                            return(
                                breadcrumb.links[index] !== undefined && breadcrumb.links[index] !== ''?
                                    <Link to={breadcrumb.links[index]} key={'bread'+index}>{e === 'Tienda'? ''+e:' > '+e}</Link>
                                :<span key={'bread'+index}>{e === 'Tienda'? ''+e:' > '+e}</span>
                            )
                        })
                        :<div></div>}
                    </div>
                    {/* {this.bread()} */}
                </div>
            )
        }
    }
}
navbar.contextType = CarContext;
export default navbar