import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import '../styles/styles.scss'

import Loader from "../components/loader";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLink } from "@fortawesome/free-solid-svg-icons";
// import { faFacebook,faTwitter,faLinkedin,faYoutube } from "@fortawesome/free-brands-svg-icons"
import Card from "../components/card";
import Wrapper from "../hooks/wrapper";
import {messageService} from "../hooks/global";

class instructor extends React.Component{
    constructor() {
        super();
        this.state= {
            loader:true,
            instructor:{},
            keys:[]
        }
    }
    componentDidMount(){
        let url = window.location.pathname
        url = url.split('/')
        url = url[2]
        messageService.sendMessage('Tienda,/ > Autor > '+ url,'bread')
        this.setState({nav:Apis.navbar})
        this.getData(Url+'authors/'+url,'instructor')
    }
    componentDidUpdate(){
        this.decri()
    }
    async getData(data,st,loader){
        const instr = await Wrapper.getData(data)
        console.log(instr);
        this.setState({[st]:instr.data.data.author})
        this.getKeys()
    }
    getKeys(){
        const {instructor} = this.state
        if (instructor !== null) {
            this.setState({keys:Object.keys(instructor.Courses)})
        }
    }
    decri(){
        let des = document.getElementById("decri")
        if (des !== null) {
            des.innerHTML = this.state.instructor.AuthorDescription
        }
        
    }
    render(){
        const {instructor,keys} = this.state
        if (keys.length === 0) {
            return <Loader />
        }else {
            const {AuthorName,Courses} = instructor
            // console.log(Courses);
            return(
                <div>
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="w-80 m-auto mt-40">
                        <div className="row">
                            <div className="col s12 l9">
                                <strong>Instructor</strong>
                                <h6>{AuthorName}</h6>
                                
                                <hr></hr>
                                <h6>Sobre mi</h6>
                                <div id="decri"></div>
                                <hr className="mt-40"></hr>
                                <h6>Mis cursos</h6>
                                <div className="row">
                                    {keys.map((e,ind)=>{
                                        return(
                                            <div className="col s12 l4" key={"mis"+ind}>
                                                <Card data={Courses[e]}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col s12 l3 center-align stiky-f">
                                <img src={Courses[keys[0]].CourseThumbnail} className="w-100" alt="autor"/>
                                {/* <div className="mt-20">
                                    <button className="waves-effect waves-light-blue btn bg-orange w-70 m-auto">
                                        <FontAwesomeIcon icon={faLink}/>
                                        <span className="ml-5">SITIO WEB</span>
                                    </button>
                                </div>
                                <div className="mt-20">
                                    <button className="waves-effect waves-light-blue btn bg-orange w-70 m-auto">
                                        <FontAwesomeIcon icon={faTwitter}/>
                                        <span className="ml-5">TWITTER</span>
                                    </button>
                                </div>
                                <div className="mt-20">
                                    <button className="waves-effect waves-light-blue btn bg-orange w-70 m-auto">
                                        <FontAwesomeIcon icon={faFacebook}/>
                                        <span className="ml-5">FACEBOOK</span>
                                    </button>
                                </div>
                                <div className="mt-20">
                                    <button className="waves-effect waves-light-blue btn bg-orange w-70 m-auto">
                                        <FontAwesomeIcon icon={faLinkedin}/>
                                        <span className="ml-5">LINKEDIN</span>
                                    </button>
                                </div>
                                <div className="mt-20">
                                    <button className="waves-effect waves-light-blue btn bg-orange w-70 m-auto">
                                        <FontAwesomeIcon icon={faYoutube}/>
                                        <span className="ml-5">YOUTUBE</span>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <Foter/> */}
                </div>
            )
        }
    }
}

export default instructor