import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import Wrapper from "../hooks/wrapper";

import Loader from "../components/loader";
import Tab from "../components/tab";
import Buycurses from "../components/buycurses";
import {messageService} from "../hooks/global";

class curses extends React.Component {
    constructor() {
        super();
        this.state= {
            loader:true,
            nav:{},
            curses:{}
        }
    }
    componentDidMount(){
        this.setState({nav:Apis.navbar})
        let id = window.location.href
        id = id.split('/')
        id = id[id.length-1]
        this.getData(Url+'courses/'+id,'curses')
    }
    async getData(data,st){
        const courses = await Wrapper.getData(data)
        if (courses !== undefined) {
            this.setState({[st]:courses.data.data.course})
            messageService.sendMessage('Tienda,/ > Cursos > '+courses.data.data.course.CourseName,'bread')
        }
    }
    render(){
        if (Object.keys(this.state.curses).length === 0) {
            return (<Loader />)
        }else{
            const {CourseName,CourseDescription} = this.state.curses
            return(
                <div className="white">
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="w-80 m-auto p-20">
                        <h5>{CourseName}</h5>
                        <div className="row">
                            <div className="col s12 l8">
                                <p>{CourseDescription}</p>
                                <Tab data={this.state.curses}/>
                            </div>
                            <div className="col s12 l4 stiky-f">
                                <Buycurses data={this.state.curses}/>
                            </div>
                        </div>
                    </div>
                    {/* <Foter/> */}
                </div>
            )
        }
    }
}
export default curses