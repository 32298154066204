import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShoppingCart,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
// import Axios from "axios";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";

function HomeButton(props) {
    let history = useHistory();
    async function handleClick(){
        const user = await Wrapper.getData(Url+'users')
        console.log(user);
        if (user !== undefined) {
            if (user.data.data.user_full_name === 'Invitado') {
                window.location.href = 'https://accounts.xpertcad.com/?APPID=4'
            }else{
                // console.log('props.data');
                // console.log(props.data);
                const orders = await Wrapper.postData(Url+'orders/'+props.data,false)
                // console.log(orders);
                if (orders !== undefined) {
                    localStorage.setItem('orden',JSON.stringify(orders.data.data.Order))
                    localStorage.setItem('metodos',JSON.stringify(orders.data.data.PaymentMethods))
                    history.push("/checkout");
                }
            }
        }
    }
  
    return (
        <button className="waves-effect btn bg-orange w-100" onClick={handleClick}>
            {/* <FontAwesomeIcon icon={faShoppingCart}/> */}
            <span className="ml-10">PROCEDER CHECKOUT</span>
            {/* <FontAwesomeIcon icon={faArrowRight} className="ml-10"/> */}
        </button>
    );
  }

export default HomeButton