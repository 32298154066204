import React from "react";
import { Link } from "react-router-dom";
class alerta extends React.Component{
    constructor(){
        super();
        this.state={

        }
    }
    render(){
        let data = typeof this.props.data
        const {cant} = this.props
        if (data !== 'string' && this.props.data !== undefined) {
            var {description,item_info,item_id} = this.props.data
            var {item_thumbnail} = item_info
        }

        return(
            <div className="row newBuy">
                <div className="col s12 l4"></div>
                <div className="col s6 l4"></div>
                <div className="col s6 l4 sp">
                    <div className="card">
                        {data === 'string'?
                        <div className="p-10 text-center">
                            <strong>El curso ya esta agregado en el carrito</strong>
                            <div className="p-10 text-center">
                                <button className="btn bg-orange" onClick={()=>this.props.activeA()}><span className="fz-9">Seguir comprando</span></button>
                                <Link to='/Mycar' className="btn grey darken-4 ml-10 fz-9" onClick={()=>this.props.activeA()}><span>Finalizar compra</span></Link>
                            </div>
                        </div>:
                        <div className="row sm">
                            <div className="col s4 sp">
                                <img src={item_thumbnail} className="w-100 imgBuy" alt="newBuy"/>
                            </div>
                            <div className="col s8">
                                <p><Link to={"/Courses/"+item_id}>{description}</Link> fue agregado </p>
                                <p>{cant} cursos en el carrito</p>
                                <div className="centrar mb-10 p-10">
                                    <button className="btn bg-orange" onClick={()=>this.props.activeA()}><span className="fz-9">Seguir comprando</span></button>
                                    <Link to='/Mycar' className="btn grey darken-4 ml-10 fz-9" onClick={()=>this.props.activeA()}><span>Finalizar compra</span></Link>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default alerta