import React from "react";
import Card from "./card";
import Loader from "./loader";
import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";

const filtrosCursos = [
    {title:'CATEGORIAS',lista:[
        {list:'Industria 4.0', value:1},
        {list:'llot', value:2},
        {list:'Tecnologia', value:3},
    ]},
    {title:'DURACIÓN DEL CURSO',lista:[
        {list:'Menos de 2 horas', value:'[0,2]'},
        {list:'2-10 horas', value:'[2,10]'},
        {list:'11-20 horas', value:'[11,20]'},
        {list:'Más de 20 horas', value:'[20]'},
    ]},
    {title:'DIFICULTAD DEL CURSO',lista:[
        {list:'Principiante', value:'A'},
        {list:'Intermedio', value:'B'},
        {list:'Avanzado', value:'C'},
        {list:'Experto', value:'E'},
    ]},
    {title:'PROVEEDOR DEL CURSO',lista:[
        {list:'Xpertcad', value:1},
        {list:'BUAP', value:2},
        {list:'XCRM', value:3},
        {list:'CERN', value:4},
        {list:'CFE', value:5},
        {list:'KIEM', value:6},
    ]},
]
class popular extends React.Component{
    constructor() {
        super();
        this.state= {
            keys:[],
            cursos:[],
            acordeon:[],
            categories:[],
            duration:[],
            level:[],
            author:[],
            filtros:{}
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.clearFiltros = this.clearFiltros.bind(this);
    }
    componentDidMount(){
        this.setState({keys:Object.keys(this.props.pop)})
        this.separar()
    }
    handleInputChange(event) {
        const {categories,duration,level,author} = this.state
        let categorias = categories
        let duracion = duration
        let nivel = level
        let autor = author
        const target = event.target;
        // console.log(target.checked);
        switch (target.className) {
            case 'CATEGORIAS':
                if (target.checked) {
                    categorias.push(parseInt(target.value))
                }else{
                    categorias.splice(categorias.length-1,1)
                }
                break;
            case 'DURACIÓN DEL CURSO':
                let parse = JSON.parse(target.value)
                if (target.checked) {
                    duracion.push(parse)
                }else{
                    duracion.splice(duration.length-1,1)
                }
                break;
            case 'DIFICULTAD DEL CURSO':
                if (target.checked) {
                    nivel.push(target.value)
                }else{
                    nivel.splice(nivel.length-1,1)
                }
                break;
            case 'PROVEEDOR DEL CURSO':
                if (target.checked) {
                    autor.push(parseInt(target.value))
                }else{
                    autor.splice(autor.length-1,1)
                }
                break;
            default:
                break;
        }
        this.filtrarCurso()
    }
    async filtrarCurso(){
        let filtro = {}
        const {categories,duration,level,author} = this.state
        filtro.categories = categories
        filtro.duration = duration
        filtro.level = level
        filtro.author = author
        this.setState({filtros:filtro})
        setTimeout(() => {
            this.postFiltros(this.state.filtros)
            // console.log(this.state.filtros);
        }, 100);
    }
    clearFiltros(){
        this.setState({categories: []})
        this.setState({duration: []})
        this.setState({level: []})
        this.setState({author: []})
        document.getElementById('filtrosC').reset()
        setTimeout(() => {
            this.filtrarCurso()
        }, 100);
    }
    async postFiltros(filtros){
        const filtro = await Wrapper.postData(Url+'courses',filtros)
        // console.log(filtro);
        if (filtro.data.data !== undefined) {
            let acordeon = document.querySelectorAll('.acordeon')
            acordeon.forEach(e=>{
                e.classList.add('oculto')
            })
            this.setState({cursos:filtro.data.data.courses})
        }else{
            let acordeon = document.querySelectorAll('.acordeon')
            acordeon.forEach(e=>{
                e.classList.add('oculto')
            })
            this.setState({cursos:filtro.data.courses})
        }
        // console.log(filtro.data.data.courses);

    }
    separar(){
        let cursos = []
        let acordeon = []
        for (let x = 0; x <= 5; x++) {
            cursos.push(this.props.pop[x])
        }
        for (let x = 6; x <= this.props.pop.length-1; x++) {
            acordeon.push(this.props.pop[x])
        }
        this.setState({cursos:cursos})
        this.setState({acordeon:acordeon})
    }
    ver(){
        let acordeon = document.querySelectorAll('.acordeon')
        acordeon.forEach(e=>{
            e.classList.toggle('oculto')
        })
    }
    render(){
        const { keys,cursos,acordeon } = this.state
        // const { pop } = this.props
        if (keys.length === 0) {
            return(
                <Loader/>
            )
        }else{
            return(
                <div className="grey lighten-4 p-5 center-align" id="titleC">
                    <h2>Nuestros cursos</h2>
                    <div className="w-90 m-auto">
                        <div className="row">
                            <div className="col s12 l3 text-left">
                                <form id="filtrosC">
                                    {filtrosCursos.map((e,index)=>{
                                        return(
                                            <div key={"filtro"+index}>
                                                <strong className="title-filtro">{e.title}</strong>
                                                {e.lista.map((f,indice)=>{
                                                    return(
                                                        <p key={"filtro-list"+indice}>
                                                            <label>
                                                                <input
                                                                name={"filtro"+indice}
                                                                className={e.title}
                                                                type="checkbox"
                                                                value={f.value}
                                                                checked={this.state.isGoing}
                                                                onChange={this.handleInputChange} />
                                                                <span>{f.list}</span>
                                                            </label>
                                                        </p>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </form>
                                <hr className="bg-orange"/>
                                <p className="text-orange centrar pointer" onClick={this.clearFiltros}><i className="material-icons-outlined text-orange">refresh</i> Restablecer filtros</p>
                            </div>
                            <div className="col s12 l9 stiky-f">
                                <div className="row">
                                    {cursos.length > 0 ? 
                                    cursos.map((e,index)=>{
                                        return(
                                            <div className="col s12 l4" key={"desta"+index}>
                                                <Card position="" data={e}/>
                                            </div>
                                        )
                                    })
                                    :<div className="text-center">
                                        <p><strong>No hay cursos disponibles con este filtro</strong></p>
                                        <button className="btn bg-orange" onClick={this.clearFiltros}>Limpiar filtros</button>
                                    </div>}
                                </div>
                                {cursos.length > 0 && Object.keys(this.state.filtros).length === 0?
                                <button className="btn outline acordeon" onClick={this.ver}>Ver más</button>
                                :<div></div>}
                                <div className="row oculto acordeon">
                                    {acordeon.map((e,index)=>{
                                        return(
                                            <div className="col s12 l4" key={"desta"+index}>
                                                <Card position="" data={e}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
export default popular