import React from "react";
import Apis from "../hooks/apis";
import Loader from "../components/loader";
// import Navbar from "../components/navbar";
// import Foter from "../components/foter";
import { Link } from "react-router-dom";

class thanks extends React.Component {
    constructor() {
        super();
        this.state= {
            loader:true,
            nav:{},
        }
    }
    componentDidMount(){
        this.setState({nav:Apis.navbar})
        
    }
    
    render(){
        if (Object.keys(this.state.nav).length === 0) {
            return (<Loader />)
        }else{
            return(
                <div className="hminvh-100">
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="w-60 m-auto hminvh-68">
                        <div className="card center-text p-20 text-center mt-50">
                            <h2>¡Gracias por tu compra!</h2>
                            <p><strong>Conoce mas de nuestros cursos con nuestros profecionales u observa tus pedidos</strong></p>
                            <Link to='/' className="mt-20 btn bg-orange">Ver más</Link>
                            <Link to='/MyOrdes' className="mt-20 btn grey darken-4 ml-20">Mis pedidos</Link>
                        </div>
                    </div>
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}
export default thanks