import React from "react";
import Url from "../hooks/url";
import Apis from "../hooks/apis";
import {messageService} from "../hooks/global";
import Functions from "../hooks/functions";
// import axios from "axios";

import Loader from "../components/loader";
// import Navbar from "../components/navbar";
// import Foter from "../components/foter";
import Upload from "../components/upload";
import Oxxo from "../components/methodsBuy/oxxo";
import Transfer from "../components/methodsBuy/transfer";
import Product from "../components/product";
import Redireccionar from "../components/redireccionar";
import imgPaypal from "../img/paypal-logo-png-transparent.png";
import imgOxxo from "../img/oxxo.png";
import imgVisa from "../img/visa.png";
import imgTrans from "../img/deposito.PNG";
// metodos de pago
import PayMethod from "../components/methodsBuy/payMethod";
import { Link } from "react-router-dom";
import wrapper from "../hooks/wrapper";

class singleOrden extends React.Component {
    constructor() {
        super();
        this.state= {
            data:{},
            nav:{},
            key:[],
            vista:'singleOrden',
            single:{},
            ordenData:{},
            carga:'0',
            btn:'1',
            vct:'metodos',
            selectMetodoCompra:0,
            comprobante:true,
            transferencia:0,
            autor:false,
            compra:0,
            factura:0,
            facturaD:''
        }
        this.changeVista = this.changeVista.bind(this);
        this.btnBuy = this.btnBuy.bind(this);
        this.selectMB = this.selectMB.bind(this);
        this.realizarCompra = this.realizarCompra.bind(this);
    }
    componentDidMount(){
        this.setState({nav:Apis.navbar})
        // this.getData(Url+'orders','data')
        this.getUrl()
    }
    setFactura(contador,doc){
        // console.log(contador);
        if (contador === 1 && this.state.factura === 0) {
            this.setState({factura:contador})
            this.setState({facturaD:doc})
        }
    }
    getUrl(){
        let url = window.location.pathname
        url = url.split('/')
        if (url[2] === 'sales') {
            messageService.sendMessage('Tienda,/ > Autor > Mis ventas,/author/sales/ > Venta #'+url[3],'bread')
            this.getSingleOrdenA(url[3])
            this.setState({autor:true})
        }else{
            messageService.sendMessage('Tienda,/ > Mis pedidos,/MyOrdes > Pedido #'+url[2],'bread')
            // console.log(url[2]);
            this.getSingleOrden(url[2])
        }
    }
    async getSingleOrdenA(id){
        const {carga} = this.state
        if (carga === '0') {
            const orders_sellers = await wrapper.getData(Url+'orders_sellers/'+id)
            // console.log(orders_sellers.data.data);
            this.setState({ordenData:orders_sellers.data.data})
            this.setState({carga:'1'})
        }
        else{
            // this.setState({ordenData:{}})
            return false
        }
    }
    async getSingleOrden(id){
        const {carga} = this.state
        if (carga === '0') {
            const orders = await wrapper.getData(Url+'orders/'+id)
            // console.log(orders);
            if (orders !== undefined) {
                this.setState({ordenData:orders.data.data})
                this.setState({carga:'1'})
            }
        }
        else{
            this.setState({ordenData:{}})
            return false
        }
    }
    
    async down(url){
        // var xmlDoc=this.cargaDocXML(this.state.facturaD);
        const xml = await wrapper.getData(url)
        console.log(xml);
        // var xmltext = "<sometag><someothertag></someothertag></sometag>";
        // var pom = document.createElement('a');

        // var filename = "file.xml";
        // pom = document.createElement('a');
        // var bb = new Blob([xmltext], {type: 'text/plain'});

        // pom.setAttribute('href', window.URL.createObjectURL(bb));
        // pom.setAttribute('download', filename);

        // pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        // pom.draggable = true; 
        // pom.classList.add('dragout');

        // pom.click();
    }
    methodPago(metodo,data){
        // console.log('metodo');
        // console.log(metodo);
        switch (metodo) {
            case '0':
                return(
                    <div>
                        <h2>Estado: {data.OrderStatusName}</h2>
                        <h5>Total: {data.OrderTotalTotal} {data.OrderCurrency}</h5>
                        <h6>Fecha: {Functions.fechaFormat(data.OrderDate)}</h6>
                        <h6>Metodo de pago: Nuevo</h6>
                    </div>
                )
            case '1':
                // console.log(data);
                return(
                    <div>
                        <h2>Estado: {data.PaymentInfo.Status}</h2>
                        <h5>Total: {data.PaymentInfo.Total} {data.PaymentInfo.Currency}</h5>
                        <h6>Metodo de pago: {data.PaymentInfo.PaymentService}</h6>
                        <div>
                            <strong className='mr-5'>Fecha:</strong>
                            {Functions.fechaFormat(data.PaymentInfo.Date)}
                        </div>
                        <div>
                            <strong className='mr-5'>Correo:</strong>
                            {data.PaymentInfo.PayerEmail}
                        </div>
                    </div>
                )
            case '2':
                const {Amount,Currency,PaymentMethod,Reference} = data.PaymentInfo.stub
                return(
                    <div>
                        <h2>Estado: {data.OrderStatusName}</h2>
                        <h5>Referencia: {Reference}</h5>
                        <h5>Total: {Amount} {Currency}</h5>
                        <h6>Fecha: {Functions.fechaFormat(data.OrderDate)}</h6>
                        <h6>Metodo de pago: {PaymentMethod}</h6>
                        <Oxxo data={data.PaymentInfo.stub}/>
                    </div>
                )
            case '3':
                return(
                    <div>
                        <h2>Estado: {data.PaymentInfo.Status}</h2>
                        <h5>Total: {data.PaymentInfo.Total} {data.PaymentInfo.Currency}</h5>
                        <h6>Fecha: {Functions.fechaFormat(data.PaymentInfo.Date)}</h6>
                        <h6>Metodo de pago: {data.PaymentInfo.CardType}</h6>
                        <h6>Banco: {data.PaymentInfo.CardBank} {data.PaymentInfo.CardBrand}</h6>
                    </div>
                )
            case '4':
                return(
                    <div>
                        <h2>Estado: {data.OrderStatusName}</h2>
                        <h5>Total: {data.OrderTotalTotal} {data.OrderCurrency}</h5>
                        <h6>Fecha: {Functions.fechaFormat(data.OrderDate)}</h6>
                        <h6>Metodo de pago: Trasferencia</h6>
                        <h6>Orden de pago: {data.OrderPaymentOrder}</h6>
                        <Transfer data={data.PaymentInfo.payment_ticket}/>
                    </div>
                )
            default:
                break;
        }
    }
    btnBuy(buton){
        this.setState({btn:buton})
    }
    Selectbotones(btn){
        // console.log(btn);
        const {ordenData,selectMetodoCompra} = this.state
        switch (btn) {
            case '0':
                const {btn,compra} = this.state
                return(
                    <div>
                        <div id="paypal-button-container" className={ btn === '0' ?'mt-20':'oculto'}></div>
                        {compra === 0?
                        <button className={ btn === '1' ?'btn blue darken-3 mt-20 w-100 mt-20':'oculto'} onClick={this.realizarCompra} disabled={selectMetodoCompra === 0?true:false}>Realizar compra</button>
                        :<div></div>}
                    </div>
                )
            // case '1' || '3':
            //     return(
            //         <button className="btn blue darken-3 w-100">SOLICITAR FACTURA</button>
                // )
            case '2':
                return(
                    <div>
                        <button className="btn green darken-3 mt-20 w-100" onClick={()=>this.imprimir('oxxoP')} disabled={ordenData.OrderStatusName === "Cheking"?true:false}>Descargar Bauche</button>
                        {/* <button className="btn blue darken-3 mt-20 w-100" onClick={()=>this.changeVista('formLoad',{})}>Subir comprobante</button> */}
                    </div>
                )
            case '4':
                return(
                    <div>
                        <button className="btn green darken-3 mt-20 w-100" onClick={()=>this.imprimir('transisio')}>Descargar Bauche</button>
                        {ordenData.OrderStatusName !== "Checking"?
                        <button className="btn blue darken-3 mt-20 w-100" onClick={()=>this.changeVista('formLoad',{})} disabled={ordenData.OrderStatusName === "Cheking"?true:false}>Subir comprobante</button>
                        :<div></div>}
                    </div>
                )
            default:
                break;
        }
    }
    imprimir(data){
        this.setState({comprobante:false})
        setTimeout(() => {
            this.setState({comprobante:true})
            Functions.imprimir(data)
        }, 500);
    }
    junto(data){
        let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let fecha = data.split('-')
        let juntos = Array.from(data.split('-')[2])
        // console.log(juntos);
        let final = juntos[0]+juntos[1]+'/'+mes[fecha[1]-1]+'/'+fecha[0]
        return final
    }
    infoPayment(data){
        const {ordenData}=this.state
        const {PaymentInfo}=ordenData
        switch (data) {
            case "1":
                return(
                    <div>
                        <strong>Total:</strong><br/>
                        <span>{Functions.formatPrice(PaymentInfo.Total)} {PaymentInfo.Currency}</span><br/>
                        <div className="mt-20"><strong>Fecha:</strong></div>
                        {this.junto(PaymentInfo.Date)}
                        <div className="mt-20"><strong>Correo:</strong></div>
                        <span className="correo">{PaymentInfo.PayerEmail}</span><br/>
                    </div>
                )
            case "2":
                return(
                    <div>
                        <strong>Total:</strong><br/>
                        <span>{Functions.formatPrice(ordenData.OrderTotalTotal)}</span><br/>
                        <div className="mt-20"><strong>Fecha:</strong></div>
                        <span>{Functions.fechaFormat(ordenData.OrderDate)}</span><br/>
                        <div className="mt-20"><strong>Referencia:</strong></div>
                        <span>{PaymentInfo.stub.Reference}</span>
                    </div>
                )
                case "3":
                    return(
                        <div>
                            <strong>Total:</strong><br/>
                            <span>{Functions.formatPrice(PaymentInfo.Total)} {PaymentInfo.Currency}</span><br/>
                            <div className="mt-20"><strong>Fecha:</strong></div>
                            <span>{Functions.fechaFormat(PaymentInfo.Date)}</span><br/>
                            <div className="mt-20"><strong>Banco:</strong></div>
                            <span>{PaymentInfo.CardBank} {PaymentInfo.CardBrand}</span><br/>
                            <div className="mt-20"><strong>Tipo detarjeta:</strong></div>
                            <span>{PaymentInfo.CardType}</span><br/>
                            <div className="mt-20"><strong>Tarjeta:</strong></div>
                            <span>**** **** **** {PaymentInfo.CardLast4}</span>
                            {/* <span>{PaymentInfo.stub.Reference}</span> */}
                        </div>
                    )
                case "4":
                    return(
                        <div>
                            <strong>Total:</strong><br/>
                            <span>{Functions.formatPrice(PaymentInfo.payment_ticket.Amount)} {PaymentInfo.payment_ticket.Currency}</span><br/>
                            <div className="mt-20"><strong>Fecha:</strong></div>
                            {this.junto(ordenData.OrderDate)}
                            <div className="mt-20"><strong>Banco:</strong></div>
                            <span className="correo">{PaymentInfo.payment_ticket.Bank}</span><br/>
                            <div className="mt-20"><strong>Clabe:</strong></div>
                            <span className="correo">{PaymentInfo.payment_ticket.CLABE}</span><br/>
                            <div className="mt-20"><strong>Referencia:</strong></div>
                            <span className="correo">{PaymentInfo.payment_ticket.Reference}</span><br/>
                        </div>
                    )
            default:
                break;
        }
    }
    
    vistaFactura(data){
        const {comprobante} = this.state
        if (data !== undefined) {
            if (data.PaymentUrl !== undefined && comprobante) {
                return(
                    <div className="comprobante">
                        <h5>Mi comprobante</h5>
                        <embed src={data.PaymentUrl} type="application/pdf" width="100%" height="300px" />
                    </div>
                )
            }
        }
    }
    vistaOrden(){
        const {autor,ordenData} = this.state
        // console.log(ordenData);
        if (autor) {
            // const {OrderSellerTotalAmount,OrdersSellerTotalTax,OrderSellerTotalTotal,OrderSellerCurrency} = ordenData
            let OSellerItems = ordenData.OSellerItems
            return(
                <div className="card text-center p-20">
                    <h5 className="text-left">Cursos vendidos</h5>
                    <hr/>
                    {OSellerItems.map((f,indice)=>{
                        const {OSellerItemDescription,OSellerItemTotalAmount,OSellerItemTotalTax,OSellerItemTotalTotal,OSellerItemRefModule,OSellerItemQty,OSellerItemCurrency} = f
                        // console.log(ordenData);
                        return(
                            <div key={"courses"+indice} className="container text-left">
                                {/* <Product data={ordenData.SubOrders[e].OSellerItems[f]} /> */}
                                <h5>{OSellerItemDescription}</h5>
                                <div className="row">
                                    <div className="col s6 l6 text-left">
                                        <div><small>Tipo de articulo: <strong>{OSellerItemRefModule}</strong></small></div>
                                        <div><small>Cantidad de articulos: <strong>{OSellerItemQty}</strong></small></div>
                                    </div>
                                    <div className="col s6 l6 text-right">
                                        <div>Subtotal: {Functions.formatPrice(OSellerItemTotalAmount)} {OSellerItemCurrency}</div>
                                        <div>IVA: {Functions.formatPrice(OSellerItemTotalTax)} {OSellerItemCurrency}</div>
                                        <div>Total: {Functions.formatPrice(OSellerItemTotalTotal)} {OSellerItemCurrency}</div>
                                    </div>
                                </div>
                                {/* <Product data={ordenData.SubOrders[e].OSellerItems[f]} /> */}
                                {/* <hr/> */}
                            </div>
                        )
                    })}
                </div>
            )
        }else{
            let Sub = Object.keys(ordenData.SubOrders)
            // console.log(ordenData);
            return(
                <div className="card text-center p-20">
                    {Sub.map((e,index)=>{
                        let keys = Object.keys(ordenData.SubOrders[e].OSellerItems)
                        const {OrderSellerID,InvoiceInfo} = ordenData.SubOrders[e]
                        let contador = 0
                        if (InvoiceInfo.OSInvoiceStatusName === 'Done') {
                            this.setFactura(contador+1,InvoiceInfo.OSInvoiceFileUrlXML)
                        }
                        // const {autor} = this.state
                        // console.log(ordenData.SubOrders[e])
                        return(
                            <div key={"listc"+index}>
                                {Object.keys(ordenData.SubOrders[e].InvoiceInfo).length > 0 ?
                                <div className="row">
                                    <div className="col s6 l6">
                                        <p>La factura ya ha sido solicitada el {Functions.fechaFormat(ordenData.SubOrders[e].InvoiceInfo.OSInvoiceRequestDate)}</p>
                                    </div>
                                    <div className="col s6 l6 text-right">
                                        <div>Estado:</div>
                                        <div><span className={ordenData.SubOrders[e].InvoiceInfo.OSInvoiceStatusName === 'Done'?"estado green accent-2 white-text pl-5 pr-5":ordenData.SubOrders[e].InvoiceInfo.OSInvoiceStatusName === 'New'?"estado blue darken-3 white-text pl-5 pr-5":ordenData.SubOrders[e].InvoiceInfo.OSInvoiceStatusName === 'Pending'?"estado grey lighten-1 white-text pl-5 pr-5":ordenData.SubOrders[e].InvoiceInfo.OSInvoiceStatusName === 'Checking'?'estado bg-orange white-text pl-5 pr-5':''}>{ordenData.SubOrders[e].InvoiceInfo.OSInvoiceStatusName}</span></div>
                                    </div>
                                </div>:
                                <div></div>}
                                <div className="row bg-orange white-text text-white p-20">
                                    <div className="col s6 l6 text-left centrar">
                                        <h6 className="white-text text-white">Autor {index+1}</h6>
                                    </div>
                                    <div className="col s6 l6 text-right centrar">
                                        {ordenData.SubOrders[e].InvoiceInfo.length === 0 && ordenData.PaymentMethods === undefined && ordenData.OrderStatusName === 'Done'?
                                        <button className="btn white text-orange ml-auto" onClick={()=>this.solicitarF(OrderSellerID)}>Solicitar factura</button>:<div></div>}
                                    </div>
                                </div>
                                {keys.map((f,indice)=>{
                                    // console.log(ordenData.SubOrders[e].OSellerItems[f]);
                                    return(
                                        <div key={"courses"+indice} className="">
                                            <Product data={ordenData.SubOrders[e].OSellerItems[f]} />
                                            <hr/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }
    vista(){
        const {vista} = this.state
        switch (vista) {
            case 'singleOrden':
                const {ordenData} = this.state
                // console.log(single);
                // let titles = ['Nombre','Cantidad','Precio','Total']
                // this.getSingleOrden(single)
                
                if (Object.keys(ordenData).length > 0) {
                    const {OrderSellerTotalAmount,OrdersSellerTotalTax,OrderSellerTotalTotal,OrderSellerCurrency,BuyingInfo} = ordenData
                    return(
                        <div className="row">
                            <div className="col s12 l8 stiky-f">
                                <div className="row">
                                    <div className="col s6">
                                        {!this.state.autor?
                                        <h5 className="mt-20">Pedido #{ordenData.OrderID}</h5>
                                        :<h5 className="mt-20">Venta #{ordenData.OrderSellerID}</h5>}
                                    </div>
                                    <div className="col s6 text-right mt-12">
                                        {!this.state.autor?
                                            <div>
                                                <div><span className={ordenData.OrderStatusName === 'Done'?"estado green accent-2 white-text pl-5 pr-5":ordenData.OrderStatusName === 'New'?"estado blue darken-3 white-text pl-5 pr-5":ordenData.OrderStatusName === 'Pending'?"estado grey lighten-1 white-text pl-5 pr-5":ordenData.OrderStatusName === 'Checking'?'estado bg-orange white-text pl-5 pr-5':''}>{ordenData.OrderStatusName}</span></div>
                                                <div><small>Realizada el: {Functions.fechaFormat(ordenData.OrderDate)}</small></div>
                                            </div>
                                        :<div>
                                            <div><span className={ordenData.OrderSellerStatusName === 'Paid'?"estado green accent-2 white-text pl-5 pr-5":ordenData.OrderSellerStatusName === 'New'?"estado blue darken-3 white-text pl-5 pr-5":ordenData.OrderSellerStatusName === 'Pending'?"estado grey lighten-1 white-text pl-5 pr-5":ordenData.OrderSellerStatusName === 'Process'?'estado bg-orange white-text pl-5 pr-5':''}>{ordenData.OrderSellerStatusName}</span></div>
                                            <div><small>Realizada el: {Functions.fechaFormat(ordenData.OrderSellerDate)}</small></div>
                                        </div>}
                                    </div>
                                </div>
                                
                                {this.vistaOrden()}
                                    
                                
                                {ordenData.PaymentMethods !== undefined?
                                <div className="card p-20">
                                    {this.vistaCompraTicket()}
                                </div>
                                :<div></div>}
                                {ordenData.OrderPaymentMethod === '2'?
                                <div className="oculto"><Oxxo data={ordenData.PaymentInfo.stub}/></div>:<div></div>}
                                {ordenData.OrderPaymentMethod === '4'?
                                <div id="transisio" className="oculto">{<Transfer data={ordenData.PaymentInfo.payment_ticket}/>}</div>:<div></div>}
                                {this.vistaFactura(ordenData.PaymentInfo)}
                            </div>
                            <div className="col s12 l4 stiky-f">
                                <div className="card text-center p-20">
                                    {
                                        !this.state.autor?
                                        <strong>Detalles del pedido</strong>
                                        :<strong>Detalles de la venta</strong>
                                    }
                                    <hr/>
                                    <div className="row">
                                    <div className="col s12 text-right">
                                            {/* {console.log(ordenData.BuyingInfo.PaymentMethodName)} */}
                                            {!this.state.autor?
                                            this.logo(ordenData.OrderPaymentMethod)
                                            :this.logo(ordenData.BuyingInfo.PaymentMethodName)}
                                        </div>
                                        <div className="col s12 text-left">
                                            {!this.state.autor?
                                            this.infoPayment(ordenData.OrderPaymentMethod):
                                            <div>
                                                <p><strong>Ganancia:</strong><br/> {Functions.formatPrice(OrderSellerTotalAmount)} {OrderSellerCurrency}</p>
                                                <p><strong>Retención:</strong><br/> {Functions.formatPrice(OrdersSellerTotalTax)} {OrderSellerCurrency}</p>
                                                <p><strong>Total de la venta:</strong><br/> {Functions.formatPrice(OrderSellerTotalTotal)} {OrderSellerCurrency}</p>
                                                <strong>Información del comprador</strong>
                                                <hr/>
                                                <p><strong>Nombre:</strong><br/> {BuyingInfo.BuyerInfo.BuyerName}</p>
                                                <p><strong>Correo:</strong><br/> {BuyingInfo.BuyerInfo.BuyerEmail}</p>
                                                <p><strong>RFC:</strong><br/> {BuyingInfo.BuyerInfo.BuyerRFC}</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        !this.state.autor?
                                        this.Selectbotones(ordenData.OrderPaymentMethod)
                                        :<div></div>
                                    }
                                    {/* <button className="btn red darken-4 mt-20 w-100" onClick={()=>this.changeVista('lista',{})}>Regresar</button> */}
                                    {
                                        !this.state.autor ? 
                                        <div>
                                            {this.state.factura>0?
                                            <a className="btn outline w-100" href={this.state.facturaD} target="_blank" rel = "noopener noreferrer" download>Descargar factura</a>
                                            // <button className="btn blue darken-4 w-100"  onClick={()=>this.down(this.state.facturaD)}>Descargar factura</button>
                                            :<div></div>}
                                            <Link to='/MyOrdes' className="btn bg-orange mt-20 w-100">Regresar</Link>
                                        </div>
                                        :<Link to='/author/sales' className="btn bg-orange mt-20 w-100">Regresar</Link>
                                    }
                                    {/* {this.methodPago(ordenData.OrderPaymentMethod,ordenData)} */}
                                </div>
                            </div>
                        </div>
                    )
                }
                else{
                    return(
                        <Loader/>
                    )
                }
            case 'formLoad':
                const { OrderPaymentOrder, OrderTotalTotal,OrderID } = this.state.ordenData
                // console.log(this.state.single);
                return(
                    <div className="card p-20 text-center mt-20">
                        <h6>Carga de bauche de pago</h6>
                        <Upload changeVista={this.changeVista} refe={OrderPaymentOrder} mount={OrderTotalTotal} id={OrderID}/>
                    </div>
                )
            default:
                break;
        }
    }
    changeVista(vista,data){
        this.setState({carga:'0'})
        this.setState({single:data})
        this.setState({vista:vista})
    }
    logo(data){
        // console.log(data);
        switch (data) {
            case '1' && 'PayPal':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgPaypal} alt="Paypal"/>
                    </span>
                )
            case '2':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgOxxo} alt="Oxxo"/>
                    </span>
                )
            case '3':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgVisa} alt="Visa"/>
                    </span>
                )
            case '4':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgTrans} alt="Transaccion"/>
                    </span>
                )
            default:
                break;
        }
    }
    vistaCompraTicket(){
        const {vct,ordenData} = this.state
        switch (vct) {
            case 'metodos':
                return(
                    <PayMethod keyMetodos={Object.keys(ordenData.PaymentMethods)} metodos={ordenData.PaymentMethods} btnBuy={this.btnBuy} ordens={ordenData} button={this.state.btn} selectMB={this.selectMB} ref={element => {this.child = element}}/>
                )
                case 'oxxo':
                    return(
                        <div>
                            <h6 className="text-center">Realiza tu deposito</h6>
                            <Oxxo id={ordenData.OrderID} data={{}}/>
                            <Redireccionar link="/ThankYou"/>
                        </div>
                    )
                case 'trans':
                    if (this.state.transferencia === 0) {
                        return(
                            <div>
                                <h6>Realiza tu deposito</h6>
                                <Transfer id={ordenData.OrderID} data={{}}/>
                                <Redireccionar link="/ThankYou"/>
                            </div>
                        )
                    }
                    break;
            default:
                break;
        }
    }
    selectMB(data){
        this.setState({selectMetodoCompra:data})
    }
    realizarCompra(){
        const {selectMetodoCompra} = this.state
        switch (selectMetodoCompra) {
            case 2:
                this.setState({vct:'oxxo'})
                break;
            case 3:
                this.child.buyTDC();
                break;
            case 4:
                this.setState({vct:'trans'})
                break;
            default:
                break;
        }
    }
    async solicitarF(id){
        const factura = await wrapper.postData(Url+'orders_sellers/'+id+'/invoices')
        // console.log(factura);
        if (factura !== undefined) {
            if (factura.data.data.requested === 'ok') {
                this.setState({carga:'0'})
                this.getUrl()
            }
        }
    }
    render(){
        const {nav} = this.state
        if (Object.keys(nav).length === 0) {
            return (<Loader />)
        }else{
            // const {others,featured} = this.state.data.courses
            return(
                <div className="grey lighten-4">
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="w-80 m-auto hminvh-75">
                        {this.vista()}
                    </div>
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}
export default singleOrden