import React from "react";
import logo from "../img/X-xpertcad3.png";

class baner extends React.Component {
    buton(){
        switch (this.props.type) {
            case 'home':
                return(
                    <a href="/#titleC" className="btn bg-orange">
                        {/* <div className="text-orange pointer"> */}
                        <div>
                                <i className="material-icons-outlined">arrow_downward</i>
                        </div>
                    </a>
                )
            case 'service':
                return(
                    <div className="waves-effect btn bg-orange">
                        Comprar
                    </div>
                )
            default:
                break;
        }
    }
    render(){
        const {descrip} = this.props
        return(
            <div className="row">
                <div className="col s12 l12 centrar banner"></div>
                <div className="col s12 l12 centrar hvh-100 text-center">
                    <div className="leyen w-50 m-auto">
                        <h3><img src={logo} width="40%" alt="logo"/><span className="text-orange ml-20 mr-20">|</span><strong className="text-grey">Store</strong></h3>
                        <h4 className="grey-text text-darken-4">{descrip}</h4>
                        {this.buton()}
                    </div>
                </div>
            </div>
        )
    }
}
export default baner