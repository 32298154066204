import React from "react";
import Axios from "axios";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";
import Loader from "../components/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

class upload extends React.Component{
    constructor() {
        super();
        this.state= {
            files:{},
            banks:[],
            fileName:'',
            month:['01','02','03','04','05','06','07','08','09','10','11','12']
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileData = this.fileData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
    }
    async componentDidMount(){
        const {month}= this.state
        let hoy = new Date()
        hoy = hoy.getFullYear()+'-'+month[hoy.getMonth()]+'-'+hoy.getDate()
        this.setState({fecha:hoy})
        const banks = await Wrapper.getData(Url+'/catalogs/banks')
        this.setState({banks:banks.data.data.banks})
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    fileData(event){
        // console.log(this.fileInput.current.files[0]);
        this.setState({files:this.fileInput.current.files[0]})
        this.setState({fileName:this.fileInput.current.files[0].name})
    }
    handleSubmit(event) {
        event.preventDefault();
        const { banco,files,fecha } = this.state
        const { refe,mount,id } = this.props
        let formData = new FormData();
        formData.append('PaymentMethodID', 4)
        formData.append('PaymentBankCode', banco)
        formData.append('PaymentFile', files)
        formData.append('CheckPayment', 1)
        formData.append('ReferenceCode', refe)
        formData.append('PaymentAmount', mount)
        formData.append('PaymentDate', fecha)
        console.log(this.state);
        Axios.post(Url+'orders/'+id+'/buying',
        formData,
            {
                headers: {
                    'Content-Type': 'multipart / form-data; límite = <calculado cuando se envía la solicitud>'
                }
            }
            ,{withCredentials:true}
        )
        .then(res=>{
            window.location.href="/MyOrdes"
            console.log(res);
        })
        .catch(err=>{
            console.log(err.response);
        })
      }
    render(){
        const {banks} = this.state
        if (Object.keys(banks).length === 0) {
            return(
                <Loader/>
            )   
        }
        else{
            return(
                <form>
                    <div className="row">
                        <div className="col s12 l4"></div>
                        <div className="col s12 l4">
                            <label>
                                <span>Fecha de pago</span>
                                <input type='date' name="fecha" onChange={this.handleInputChange} value={this.state.fecha}/>
                            </label>
                            <label>
                                <span>Banco</span>
                                <select name="banco" onChange={this.handleInputChange}>
                                    {banks.map((e,index)=>{
                                        return(
                                            <option key={'opcion'+index}>{e}</option>
                                        )
                                    })}
                                </select>
                            </label>
                            <label className="file">
                                <span>Subir archivo para verificar pago</span>
                                <input type="file" name="file" onChange={this.fileData} ref={this.fileInput} className="oculto"/>
                                <div className="dataFile">{this.state.fileName}</div>
                            </label>
                            <button className="btn red darken-4 mt-20" onClick={()=>this.props.changeVista('singleOrden',{})}>Regresar</button>
                            <button className="btn green darken-3 ml-20 mt-20" onClick={this.handleSubmit}>
                                <FontAwesomeIcon icon={faUpload} className="mr-5"/>
                                Subir comprobante
                            </button>
                        </div>
                        <div className="col s12 l4"></div>
                    </div>
                </form>
            )

        }
    }
}
export default upload