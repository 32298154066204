import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../pages/home";
import Curses from "../pages/curses";
import Instructor from "../pages/instructo";
import Car from "../pages/car";
import Orden from "../pages/orden";
import Servicios from "../pages/service";
import ListOrdes from "../pages/listOrdes";
import SingleOrden from "../pages/singleOrden";
import Thaks from "../pages/thaks";
import ListBuys from "../components/Author/listBuys";
import ListPayment from "../components/Author/listPayment";
import SinglePayment from "../components/Author/singlePayment";
import SingleInvoice from "../components/Author/singleInvoice";
import ListInvoice from "../components/Author/listInvoice";
import Checkin from "../components/checkIn";
import Foter from "../components/foter";
import Navbar from "../components/navbar";
import Alerta from "../components/alerta";
// hooks
// import Functions from "../hooks/functions";
import Apis from "../hooks/apis";

function routerApp(props) {
    function activeA(){
        props.activeA()
    }
    function alerta() {
        if (props.Aalert) {
            setTimeout(() => {
                activeA()
            }, 5000);
            return(
                <Alerta data={props.dataA} cant={props.cantP} activeA={activeA}/>
            )
        }
    }
    return(
        <BrowserRouter>
            <Navbar menu={Apis.navbar} laterales={props.data}/>
            {alerta()}
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/Courses" component={Curses}/>
                <Route path="/Instructor" component={Instructor}/>
                <Route path="/Mycar" component={Car}/>
                <Route path="/checkout" component={Orden}/>
                <Route path="/service" component={Servicios}/>
                <Route exact path="/MyOrdes" component={ListOrdes}/>
                <Route path="/MyOrdes/:id" component={SingleOrden}/>
                <Route path="/ThankYou" component={Thaks}/>
                <Route path="/Check-in" component={Checkin}/>
                <Route exact path="/author/sales" component={ListBuys}/>
                <Route exact path="/author/payment" component={ListPayment}/>
                <Route exact path="/author/invoices" component={ListInvoice}/>
                <Route exact path="/author/sales/:id" component={SingleOrden}/>
                <Route exact path="/author/payment/:id" component={SinglePayment}/>
                <Route exact path="/author/invoices/:id" component={SingleInvoice}/>
            </Switch>
            <Foter/>
        </BrowserRouter>
    )
}

export default routerApp