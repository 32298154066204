import React from "react";
import Url from "../hooks/url";

import Apis from "../hooks/apis";
// import Foter from "../components/foter";
import Loader from "../components/loader";
import Baner from "../components/baner";
// import Featured from "../components/featured";
import Popular from "../components/popular";
import Wrapper from "../hooks/wrapper";
import Functions from "../hooks/functions";
import {messageService} from "../hooks/global";

class home extends React.Component {
    constructor() {
        super();
        this.state= {
            loader:true,
            nav:{},
            navData:{},
            des:{},
            data:{},
        }
    }
    async componentDidMount(){
        messageService.sendMessage('Tienda','bread')
        const inicial = await Functions.user()
        this.setState({nav:Apis.navbar})
        this.setState({navData:inicial})
        this.getData(Url,'data')
    }
    async getData(data,st){
        const home = await Wrapper.getData(data)
        if (home !== undefined) {
            this.setState({[st]:home.data.data})   
        }else{
            // window.location.reload()
        }
    }
    render(){
        if (Object.keys(this.state.data).length === 0) {
            return (
                <div>
                    <Loader />
                </div>
            )
        }else{
            const {others} = this.state.data.courses
            return(
                <div className="hminvh-100">
                    {/* <Navbar menu={this.state.nav} laterales={this.state.navData}/> */}
                    <Baner titulo="Cursos" descrip="Description de prueba para nuevos cursos del banner" type="home"/>
                    {/* <Featured des={featured}/> */}
                    <Popular pop={others}/>
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}
export default home