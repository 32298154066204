const APIS = {
    navbar:{
        "logo": "https://image.flaticon.com/icons/png/512/2000/2000887.png",
        "navlist": [
          {
            "link": "/",
            "text": "Aprende",
            "sub": [
              {
                "link": "https://academy.xpertcad.com/dashboard/gettingStart",
                "text": "Como comenzar",
                'title':'Guías de uso de la plataforma'
              },
              {
                "link": "https://academy.xpertcad.com/courses",
                "text": "Mis cursos",
                'title':'Cursos asignados por tu institución'
              },
              {
                "link": "https://academy.xpertcad.com/courses/forums",
                "text": "Foros",
                'title':'Participa en los foros activos'
              },
              {
                "link": "https://academy.xpertcad.com/feedback/feedbackList",
                "text": "Mis calificaciones",
                'title':'Ver avances de cursos asignados y resultados de tus actividades realizadas'
              }
            ]
          },
          {
            "link": "#/",
            "text": "Crea",
            "sub": [
              {
                "link": "https://academy.xpertcad.com/courses/allCoursesCreated",
                "text": "Cursos",
                'title': 'Lista de cursos donde eres autor ó colaborador'
              },
              {
                "link": "https://academy.xpertcad.com/courses/course",
                "text": "Crear cursos",
                'title': 'Crea un nuevo curso'
              },
              {
                "link": "https://academy.xpertcad.com/forum/getTeacherForums",
                "text": "Foros creados",
                'title': 'Participa en los foros de los cursos donde eres autor ó colaborador'
              }
            ]
          }
        ],
        "user": {
          "avatar": "https://academy.xpertcad.com/assets/img/navbar/user.png",
          "name": "Héctor",
          "dropdown": [
            {
              "link": "#/",
              "text": "Perfil"
            },
            {
              "link": "#/",
              "text": "cursos"
            }
          ]
        },
        "cantProduct": 3
    },
    featured:{
        "featured": [
          {
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "Curso 1",
            "hours": "35 min",
            "description": "Descricpción de curso 1",
            "price": 100,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
            "title": "Curso 2",
            "hours": "25 min",
            "description": "Descricpción de curso 2",
            "price": 150,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          }
        ]
    },
    curses:{
        "curses": [
          {
            "idAutor": 0,
            "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
            "title": "Curso 1",
            "hour": "30 min",
            "description": "Descricion de curso 1",
            "price": 50,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 1,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "Curso 2",
            "hour": "35 min",
            "description": "Descricion de curso 2",
            "price": 100,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 2,
            "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
            "title": "Curso 3",
            "hour": "30 min",
            "description": "Descricion de curso 3",
            "price": 100,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 3,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "Curso 4",
            "hour": "35 min",
            "description": "Descricion de curso 4",
            "price": 150,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 4,
            "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
            "title": "Curso 5",
            "hour": "30 min",
            "description": "Descricion de curso 5",
            "price": 50,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 5,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "Curso 6",
            "hour": "30 min",
            "description": "Descricion de curso 6",
            "price": 50,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 6,
            "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
            "title": "Curso 7",
            "hour": "35 min",
            "description": "Descricion de curso 7",
            "price": 150,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          },
          {
            "idAutor": 7,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "Curso 8",
            "hour": "30 min",
            "description": "Descricion de curso 8",
            "price": 50,
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "featuredContent": []
          }
        ]
    },
    orden:{
      "orden": [
        {
          "course": "curso 1",
          "author": "Juan Alberto Lopez Ramirez",
          "price": 58.52
        },
        {
          "course": "curso 2",
          "author": "Maria alejandra Torres Hernandez",
          "price": 58.52
        },
        {
          "course": "curso 3",
          "author": "Maria alejandra Torres Hernandez",
          "price": 58.52
        }
      ],
      "subtotal": 218.87,
      "iva": 100,
      "total": 318.87
    },
    car:{
      "listCard": [
        {
          "id": 0,
          "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
          "title": "Curso",
          "description": "lorem epsom",
          "discount": {
            "percent": 57,
            "price": 99.99
          },
          "sonud": "Español",
          "subtitle": "Español, Inglés, Portugués",
          "price":79.99,
          "featuredContent": [
            {
              "id": 0,
              "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
              "title": "titulo 1",
              "description": "lorem epsom",
              "discount": {
                "percent": 57,
                "price": 99.99
              },
              "sonud": "Español",
              "subtitle": "Español, Inglés, Portugués",
              "price":79.99
            },
            {
              "id": 1,
              "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
              "title": "titulo 2",
              "description": "lorem epsom",
              "discount": {
                "percent": 57,
                "price": 99.99
              },
              "sonud": "Español",
              "subtitle": "Español, Inglés, Portugués",
              "price":79.99
            }
          ]
        },
        {
          "id": 1,
          "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
          "title": "titulo",
          "description": "lorem epsom",
          "discount": {
            "percent": 57,
            "price": 99.99
          },
          "sonud": "Español",
          "subtitle": "Español, Inglés, Portugués",
          "price":79.99,
          "featuredContent": []
        }
      ],
      "subtotal":55,
      "discount":-10,
      "iva":25,
      "total":70
    }
}
export default APIS