import React from "react";
// import Simg from "../../img/sinImagen.png";
function detalles(props) {
    const { orden } = props
    // console.log(props);
    return(
        <div>
            {props.productos.map((e,index)=>{
                const {OSellerItemDescription,OSellerItemTotalTotal,OSellerItemCurrency,OSellerItemInfo} = orden.OSellerItems[e]
                const {OSItemThumbnail} = OSellerItemInfo
                return(
                    <div className="row" key={"produntos"+index}>
                        <div className="col s3 sp">
                            <img src={OSItemThumbnail} alt="curso" className="w-100"/>
                        </div>
                        <small className="col s4 centrar hpx-39">{OSellerItemDescription}</small>
                        <div className="col s3 centrar hpx-39">{OSellerItemTotalTotal} {OSellerItemCurrency}</div>
                        <div className="col s2 centrar hpx-39">
                            <i className="material-icons-outlined text-orange pointer">close</i>
                        </div>
                    </div>
                )
            })}
            <hr/>
            <div className="row">
                <div className="col s6">
                    {/* <p>Subtotal</p> */}
                    {/* <p>Descuento</p> */}
                    <p></p>
                    <p><strong>Total</strong></p>
                </div>
                <div className="col s6 text-right">
                    {/* <p>{orden.OrderSellerTotalAmount}</p> */}
                    {/* <p>{orden.OrderTotalPayments}</p> */}
                    <p><strong>{orden.OrderSellerTotalTotal}</strong></p>
                    <p>IVA incluido</p>
                </div>
            </div>
        </div>
    )
}
export default detalles