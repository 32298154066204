import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
// import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faEye } from "@fortawesome/free-solid-svg-icons";
import Functions from "../hooks/functions";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});


export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { headers,body,link,dataDescription,activePop,redirect,list } = props

  if (body.length > 0) {
    return (
      <div className="w-100">

        <Paper className={'mt-20'+classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className="bg-success text-orange"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                  // console.log(row);
                  var currency = ''
                  switch (list) {
                    case 'invoice':
                      currency = row.OSInvoiceCurrency
                      break;
                    case 'orders':
                      currency = row.OrderCurrency
                      break;
                    case 'ventas':
                      currency = row.OrderSellerCurrency
                      break;
                    case 'pagos':
                      currency = row.OrderSellerCurrency
                      break;
                    default:
                      break;
                  }
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={'row'+index}>
                      {headers.map((column) => {
                        const value = row[column.id];
                        // console.log(column);
                        // console.log(value);
                        return (
                          <TableCell key={column.id} align={column.align} className={column.id}>
                            {/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
                            {link === column.id ? 
                            <Link to={'/'+redirect+'/'+value} onClick={()=>dataDescription('singleOrden',value)}>{value}</Link> : 
                            column.id === 'FileURL' ?  
                            <div><a href={value} target="blank" className="btn btn-danger"><FontAwesomeIcon icon={faFilePdf}/></a> <button className="btn btn-success ml-2" onClick={()=>activePop(value)}><FontAwesomeIcon icon={faEye}/></button></div>:
                            <span className={value === 'Pending'?'text-gray':value === 'Done'?'green-text text-accent-3':value === 'New'?'blue-text text-darken-3':value === 'Checking'?'text-orange text-darken-3':''}>
                            {column.id === 'OrderTotalTotal' || column.id === 'OSInvoiceTotalTotal' || column.id === 'OrderSellerTotalTotal' || column.id === 'SPaymentTotal'?Functions.formatPriceC(value,currency)+' '+currency:
                            column.id === 'OrderDate' || column.id === 'OrderSellerDate'? Functions.fechaFormat(value) : value}</span>}
                            {/* {btn1 === undefined && btn1 === true && value === '' ?'boton':'no boton'} */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={body.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className="ml-auto w-100"
        />
      </div>
    ) 
  }
  else{
    return(
      <Loader/>
    )
  }
}
