import React from "react";
import Url from "../../hooks/url";
import axios from "axios";
import Paginador from "../paginador";
import Apis from "../../hooks/apis";
import {messageService} from "../../hooks/global";
import Wrapper from "../../hooks/wrapper";
import Loader from "../loader";
import NavAuthor from "./navAuthor";
import { Link } from "react-router-dom";

const headerss = {
    head1:[
        { id: 'SPaymentID', label: 'ID', minWidth: 170, align: 'center'},
        { id: 'SPaymentDate', label: 'Fecha', minWidth: 170, align: 'center'},
        { id: 'SPaymentTotal', label: 'Total', minWidth: 170, align: 'center'},
        { id: 'SPaymentStatusName', label: 'Estatus', minWidth: 170, align: 'center'},
    ]
}
class listPayment extends React.Component{
    constructor(){
        super();
        this.state={
            data:{},
            dataG:{},
            nav:{}
        }
    }
    componentDidMount(){
        messageService.sendMessage('Tienda,/ > Autor > Pagos','bread')
        this.setState({nav:Apis.navbar})
        this.getData(Url+'orders_sellers/1/author/payment','data')
    }
    
    async getData(data,st){
        const payment = await Wrapper.getData(data)
        console.log('payment');
        console.log(payment);
        if (payment !== undefined) {
            this.setState({[st]:payment.data.data.SalesPayments})
            this.getKeys()
        }
        // axios.get(data)
        // .then(res=>{
        //     // console.log(res.data.data.SalesPayments);
        //     this.setState({[st]:res.data.data.SalesPayments})
        //     this.getKeys()
        // })
        // .catch(error=>{
        //     console.log(error.reponse);
        // })
    }
    getKeys() {
        // const {headers,keyList,data,changeVista,vista} = props
        const {data} = this.state
        let llaves = []
        let keys = Object.keys(data)
        keys = keys.sort(this.ordenarNumeros)
        for (let a = 0; a < keys.length; a++) {
            llaves.push(data[keys[a]])
        }
        this.setState({dataG:llaves})
    }
    ordenarNumeros(valor1, valor2) {
        return valor2 - valor1;
    }
    dataDescription(datos,id){
        // console.log(datos);
        // console.log(id);
    }
    solicitar(){
        axios.post(Url+'orders_sellers/2/author/payment',{withCredentials:true})
        .then(res=>{
            // console.log(res);
        })
        .catch(error=>{
            console.log(error.reponse);
        })
    }
    render(){
        if (Object.keys(this.state.nav).length === 0) {
            return (
                <div>
                    {/* {console.log('home')} */}
                    <Loader />
                </div>
            )
        }else{
            const {dataG} = this.state
            let datosEnv = dataG
            if(Object.keys(datosEnv).length>0){
                datosEnv.map(e=>{
                    e.OrderCurrency = 'MXN'
                    return ''
                })
            }
            // let keys = Object.keys
            return(
                <div>
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="row2 row w-100 sm white-text text-white">
                        <NavAuthor/>
                        <div className="col l10">
                            <div className="w-90 hminvh-75 m-auto black-text text-black" >
                                <p><Link to="/">Inicio</Link> / Autor / Pagos</p>
                                <div className="row">
                                    <div className="row">
                                        <div className="col s6 l6"><h5 className="sm">Mis pagos</h5></div>
                                        <div className="col s6 l6 text-right">
                                            <button className="btn grey darken-4" onClick={this.solicitar}>Solicitar Pago</button>
                                        </div>
                                    </div>
                                    <div className="col s6 l6 text-right">
                                        {/* <button className="btn grey darken-4" onClick={this.solicitar}>Solicitar Pago</button> */}
                                    </div>
                                </div>
                                {Object.keys(datosEnv).length>0?
                                <Paginador headers={headerss.head1} body={datosEnv} link="SPaymentID" redirect="Author/payment" dataDescription={this.dataDescription} list='orders'/>
                                :<div className="card p-20 text-center">
                                    <h5>No hay pagos disponibles</h5>
                                </div>}
                            </div>
                        </div>
                    </div>
            
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}

export default listPayment