import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../styles/styles.scss";
// import { Link } from "react-router-dom";
import logo from "../img/xpertcad-logo.png";
// import Wrapper from "../hooks/wrapper";
// import Url from "../hooks/url";

class navLeft extends React.Component {
    constructor() {
        super();
        this.state= {
            list:[],
            active:false
        }
    }
    componentDidMount(){
        console.log(this.props);
        // this.getMenu(this.props.rol.user_id)
        setTimeout(() => {
            this.setState({list:this.props.list.navlist})
            if (this.state.list.length > 0 && this.state.list !== undefined) {
                this.setState({active:true})
            }
        }, 500);
    }
    // async getMenu(id){
    //     const menu = await Wrapper.getData(Url+'users/menu/'+id)
    //     console.log(menu);
    // }
    close(data){
        let fondo = document.querySelector('.fondo')
        let lateral = document.querySelector('.lateral')

        fondo.classList.add('fondor')
        lateral.classList.add('lateralr')
        setTimeout(() => {
            this.props.lateralActive(data)
        }, 1000);
    }
    activeLink(data){
        let list = document.querySelectorAll('.list-nav')
        for (let a = 0; a < list.length; a++) {
            list[a].classList.remove('li-active')
        }
        list[data].classList.add('li-active')
    }
    render(){
        return(
            <div className="fondo">
                <div className="lateral h-100 white">
                    <div className="menu" onClick={()=>this.close(false)}>
                        <FontAwesomeIcon icon={faBars} className="fa-1x"/>
                        <div className="listmenu w-100 mt-25 mb-40">
                            {this.props.left.map((e,index)=>{
                                return(
                                    <ul className="mt-5" key={'left'+index}>
                                        {e.ModuleClass === ''?
                                        <strong>{e.Module}</strong>:
                                        <li className="ml-18 cont-list mt-6 text-gray">
                                            <a className="list-nav text-gray" href={e.ModuleClass} onClick={()=>this.activeLink(index)}>{e.Module}</a>
                                            <div className="tooltip grey darken-4">{e.Module}</div>
                                        </li>
                                        }
                                    </ul>
                                )
                            }
                            )}
                            {/* {
                                this.state.list.map((e,index)=>{
                                    return(
                                        <div key={'div'+index}>
                                            <strong>{e.text}</strong>
                                            <ul className="ml-18 mt-5 text-gray">
                                                {
                                                    e.sub.map((f,indice)=>{
                                                        return(
                                                            <li className="ml-10 cont-list mt-6" key={'list'+indice}>
                                                                <Link className="list-nav" to={f.link} onClick={()=>this.activeLink(indice)}>{f.text}</Link>
                                                                <div className="tooltip grey darken-4">{f.title}</div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                })
                            } */}
                        </div>
                    </div>
                    <div className="marca">
                        {/* {console.log(this.props)} */}
                        <strong className="text-gray tileNav ml-11">Mi rol: {this.props.rol.user_first_name}</strong>
                        <div className="centrar power tileNav mt-12">
                            <div className="fz-14">Powered By</div>
                            <img className="w-51" src={logo} alt="Logo"/>
                        </div>
                    </div>
                </div>
                <div className="space" onClick={()=>this.close(false)}></div>
            </div>
        )
    }
}
export default navLeft